import React, { useEffect, useRef } from "react";


const FutureDivReverse = ({ des1, des2, imgPath }) => {
    const sectionRef = useRef(null);
    const imgUrl = "../../../images/background/tree1.png"
    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        // Add 'show' class when the section is in view
                        entry.target.classList.add("show");
                    } else {
                        // Optional: remove 'show' class when the section is out of view
                        entry.target.classList.remove("show");
                    }
                });
            },
            {
                threshold: 0.1, // Trigger when 10% of the section is visible
            }
        );

        const elements = sectionRef.current.querySelectorAll(".col-7, .col-4");

        elements.forEach((el) => observer.observe(el));

        // Clean up the observer when the component unmounts
        return () => {
            elements.forEach((el) => observer.unobserve(el));
        };
    }, []);

    return (
        <>
            <div className="row future-section" ref={sectionRef}>
                <div className="col-4 hidden">

                    <img
                        src={imgPath}
                        alt=""
                    />
                </div>
                <div className="col-1"></div>
                <div className="col-7 single-future-section hidden-image">

                    <p>
                        {des1}
                        <br />
                        <br />
                        {des2}
                    </p>
                </div>
            </div>
        </>
    );
}


export default FutureDivReverse;

import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { IMAGE_PREFIX } from "../server";

const NextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        right: "2%",
        top: "50%",
        transform: "translateY(-50%)",
        zIndex: 2,
      }}
      onClick={onClick}
    />
  );
};

const PrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        left: "2%",
        top: "50%",
        transform: "translateY(-50%)",
        zIndex: 2,
      }}
      onClick={onClick}
    />
  );
};

const ProductSlider2 = ({ data, sectionData }) => {
  const [sliderImages, setSliderImages] = useState([]);

  useEffect(() => {
    if (sectionData?.images) {
      setSliderImages(sectionData.images);
    }
  }, [sectionData]);

  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    arrows: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplaySpeed: 4000,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  return (
    <Slider {...settings} className="product-slider2 m-auto bg-black">
      {sliderImages.map((item, index) => (
        <div key={index} className="slider-item">
          <div
            className="slider-content-product"
            style={{
              backgroundImage: `url(${IMAGE_PREFIX}/${item.url})`,
              backgroundPosition: "center",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              height: "400px",
              position: "relative",
            }}
          >
            {/* Overlay effect */}
            <div
              style={{
                position: "absolute",
                height: "100%",
                width: "100%",
                left: 0,
                top: 0,
                background: "rgba(0, 0, 0, 0.4)",
              }}
            ></div>
          </div>
        </div>
      ))}
    </Slider>
  );
};

export default ProductSlider2;

import React from "react";
import { FaStar } from "react-icons/fa";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

const TestimonialSlider = ({ testimonials = [] }) => {
  const settings = {
    // dots: true,
    infinite: true, // Allow infinite looping
    slidesToShow: Math.min(4, testimonials.length), // Show up to 4 slides dynamically
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    speed: 1000,
    arrows: true,
  };

  return (
    <div className="cielx-testimonial-outer-box">
      <Slider {...settings} className="product-slider2">
        {testimonials.map((testimonial, index) => (
          <div
            key={index}
            className={`cielx-testimonial-card-home ${
              true ? "cielx-testimonial-card-home-1" : ""
            }`}
            // style={{ marginBottom: 60 }}
          >
            <div className="cielx-testimonial-card-content">
              <div className="star-desc">
                {Array.from(
                  { length: testimonials[index]?.rating },
                  (_, index) => (
                    <FaStar key={index} color="gold" />
                  )
                )}
                <div className="alignStretch">
                  <p className="textStyle">{testimonials[index]?.details}</p>
                </div>
              </div>
            </div>
            <div className="cielx-testimonial-card-content2">
              <div className="cielx-testimonial-card-name">
                <div className="name-designation">
                  <p className="name-text">{testimonials[index]?.name}</p>
                  <p className="designation-text">
                    {testimonials[index]?.designation}
                  </p>
                </div>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default TestimonialSlider;

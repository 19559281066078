// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./Flaticon.eot");
var ___CSS_LOADER_URL_IMPORT_1___ = require("./Flaticon.woff");
var ___CSS_LOADER_URL_IMPORT_2___ = require("./Flaticon.ttf");
var ___CSS_LOADER_URL_IMPORT_3___ = require("./Flaticon.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___, { hash: "?#iefix" });
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___, { hash: "#Flaticon" });
// Module
exports.push([module.id, "@font-face{font-family:Flaticon;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");src:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"embedded-opentype\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"woff\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format(\"truetype\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ") format(\"svg\");font-weight:400;font-style:normal}@media screen and (-webkit-min-device-pixel-ratio:0){@font-face{font-family:Flaticon;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ") format(\"svg\")}}[class*=\" flaticon-\"]:after,[class*=\" flaticon-\"]:before,[class^=flaticon-]:after,[class^=flaticon-]:before{font-family:Flaticon;font-style:normal}.flaticon-boiler:before{content:\"\\f100\"}.flaticon-conveyor:before{content:\"\\f101\"}.flaticon-pint-of-beer:before{content:\"\\f102\"}.flaticon-beer-keg:before{content:\"\\f103\"}.flaticon-beer:before{content:\"\\f104\"}.flaticon-brewing:before{content:\"\\f105\"}.flaticon-brewing-1:before{content:\"\\f106\"}.flaticon-malt:before{content:\"\\f107\"}.flaticon-distillation:before{content:\"\\f108\"}.flaticon-brewing-2:before{content:\"\\f109\"}.flaticon-barrels:before{content:\"\\f10a\"}.flaticon-barrels-1:before{content:\"\\f10b\"}.flaticon-press:before{content:\"\\f10c\"}.flaticon-brewing-3:before{content:\"\\f10d\"}.flaticon-beer-keg-1:before{content:\"\\f10e\"}.flaticon-test-tube:before{content:\"\\f10f\"}.flaticon-grinder:before{content:\"\\f110\"}.flaticon-still:before{content:\"\\f111\"}.flaticon-malt-1:before{content:\"\\f112\"}.flaticon-brewing-4:before{content:\"\\f113\"}.flaticon-brewing-5:before{content:\"\\f114\"}.flaticon-beer-1:before{content:\"\\f115\"}", ""]);
// Exports
module.exports = exports;

import React, { Component } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import Index from './pages/index'
import About1 from './pages/about-1'
import About2 from './pages/about-2'
import BlogGrid2 from './pages/blog-grid-2'
import BlogGrid2Sidebar from './pages/blog-grid-2-sidebar'
import BlogGrid2SidebarLeft from './pages/blog-grid-2-sidebar-left'
import BlogGrid3 from './pages/blog-grid-3'
import BlogGrid3Sidebar from './pages/blog-grid-3-sidebar'
import BlogGrid3SidebarLeft from './pages/blog-grid-3-sidebar-left'
import BlogGrid4 from './pages/blog-grid-4'
import BlogHalfImg from './pages/blog-half-img'
import BlogHalfImgSidebarLeft from './pages/blog-half-img-left-sidebar'
import BlogHalfImgSidebar from './pages/blog-half-img-sidebar'
import BlogLargeImg from './pages/blog-large-img'
import BlogLargeImgSidebarLeft from './pages/blog-large-img-left-sidebar'
import BlogLargeImgSidebar from './pages/blog-large-img-sidebar'
import BlogSingle from './pages/blog-single'
import BlogSingleSidebarLeft from './pages/blog-single-left-sidebar'
import BlogSingleSidebar from './pages/blog-single-sidebar'
import ComingSoon1 from './pages/coming-soon-1'
import Contact1 from './pages/contact-1'
import Contact2 from './pages/contact-2'
import Contact3 from './pages/contact-3'
import Contact4 from './pages/contact-4'
import Error403 from './pages/error-403'
import Error404 from './pages/error-404'
import Error405 from './pages/error-405'
import Faq1 from './pages/faq-1'
import Faq2 from './pages/faq-2'
import HelpDesk from './pages/help-desk'
import PortfolioDetails from './pages/portfolio-details'
import PortfolioGrid2 from './pages/portfolio-grid-2'
import PortfolioGrid3 from './pages/portfolio-grid-3'
import PortfolioGrid4 from './pages/portfolio-grid-4'
import PrivacyPolicy from './pages/privacy-policy'
import Services1 from './pages/services-1'
import Services2 from './pages/services-2'
import ServicesDetails from './pages/services-details'
import ShopCart from './pages/shop-cart'
import ShopCheckout from './pages/shop-checkout'
import ShopLogin from './pages/shop-login'
import shopProductDetails from './pages/shop-product-details'
import ShopRegister from './pages/shop-register'
import ShopSidebar from './pages/shop-sidebar'
import ShopWishlist from './pages/shop-wishlist'
import Shop from './pages/shop'
import Team1 from './pages/team-1'
import Team2 from './pages/team-2'
import Index2 from './pages/index-2'
import Index3 from './pages/index-3'
import Index4 from './pages/index-4'
import Index5 from './pages/index-5'
import Index6 from './pages/index-6'
import Index7 from './pages/index-7'
import Index8 from './pages/index-8'
import Index9 from './pages/index-9'
import Index10 from './pages/index-10'
import Index11 from './pages/index-11'
import Index12 from './pages/index-12'
import Index13 from './pages/index-13'
import Index14 from './pages/index-14'
import Index15 from './pages/index-15'
import shortButtons from './pages/shortcode-buttons'
import shortAlertBox from './pages/shortcode-alert-box'
import shortIconBoxStyles from './pages/shortcode-icon-box-styles'
import shortIconBox from './pages/shortcode-icon-box'
import shortPricingTable from './pages/shortcode-pricing-table'
import shortDividers from './pages/shortcode-dividers'
import shortListGroup from './pages/shortcode-list-group'
import shortTitleSeparators from './pages/shortcode-title-separators'
import shortImageBoxContent from './pages/shortcode-image-box-content'
import shortPagination from './pages/shortcode-pagination'
import shortCounters from './pages/shortcode-counters'
import shortTestimonials from './pages/shortcode-testimonials'
import shortImagesEffects from './pages/shortcode-images-effects'
import shopWidgets from './pages/shortcode-shop-widgets'
import shortTeam from './pages/shortcode-team'
import Mission from './pages/mission'
import Corporate from './pages/corporate'
import Nomination from './pages/nomination'
import Audit from './pages/auditComitee'
import Key from './pages/keyManagement'
import Sister from './pages/sister'
import Products from './pages/products'
import QualityTest from './pages/qualityTest'
import QualityControl from './pages/qualityControl'
import Certification from './pages/certification'
import PreSales from './pages/preSales'
import PostSales from './pages/postSales'
import SupplyChain from './pages/supplyChain'
import Environmental from './pages/environmental'
import Remarkable from './pages/remarkable'
import Financial from './pages/financial'
import Social from './pages/social'
import Technological from './pages/technological'
import Compliance from './pages/compliance'
import HR from './pages/hr'
import Jobs from './pages/jobs'
import Brochure from './pages/brochure'
import Leaflet from './pages/leaflet'
import Journey from './pages/journey'
import TVC from './pages/tvc'
import Gallery from './pages/photoGallery'
import News from './pages/news'
import OnlineCv from './pages/onlineCV'
import OrderNow from './pages/orderNow'
import OrderEdit from './pages/orderEdit'
import PrivateRoute from './routes/PrivateRoute'
import aboutUs from './pages/about-us'
import AboutUs from './pages/about-us'
import Future from './pages/future'
import ContactUs from './pages/contact-us'
import CertificateDetails from './pages/details/certificateDetails'
import TestimonialDetails from './pages/details/TestimonialDetails'
import GrowthTowardsNature from './pages/details/GrowthTowardsNature'
import ProductsPage from './pages/details/ProductsPage'
import ProductDetails from './pages/details/ProductDetails'
import ScrollToTop from './ScrollToTop'

class Router extends Component {
  render() {
    return (
      //<BrowserRouter>
      <BrowserRouter basename={'/'}>
        <div className='page-wraper'>
          <ScrollToTop />
          <Switch>
            <Route path='/' exact component={Index10} />
            <Route path='/about-1' component={AboutUs} />
            <Route path='/about-us' component={AboutUs} />
            <Route path='/future' component={Future} />
            <Route path='/mission' component={Mission} />
            <Route path='/corporate' component={Corporate} />
            <Route path='/board-of-directors' component={Team1} />
            <Route path='/nominatiom-and-renumeration' component={Nomination} />
            <Route path='/audit-commitee' component={Audit} />
            <Route path='/key-management' component={Key} />
            <Route path='/sister-concern' component={Sister} />
            <Route path='/products' component={Products} />
            <Route path='/quality-test' component={QualityTest} />
            <Route path='/quality-control' component={QualityControl} />
            <Route path='/certification' component={Certification} />
            <Route path='/pre-sales-service' component={PreSales} />
            <Route path='/post-sales-service' component={PostSales} />
            <Route path='/supply-chain' component={SupplyChain} />
            <Route path='/environmental' component={Environmental} />
            <Route path='/remarkable-customers' component={Remarkable} />
            <Route path='/financial' component={Financial} />
            <Route path='/social' component={Social} />
            <Route path='/technological' component={Technological} />
            <Route path='/compliance' component={Compliance} />
            <Route path='/hr-policy' component={HR} />
            <Route path='/available-jobs' component={Jobs} />
            <Route path='/news-events' component={BlogGrid2} />
            <Route path='/brochure' component={Brochure} />
            <Route path='/leaflet' component={Leaflet} />
            <Route path='/journey' component={Journey} />
            <Route path='/tvc' component={TVC} />
            <Route path='/testimonials' component={TestimonialDetails} />
            <Route path='/contact-us' component={ContactUs} />
            <Route path='/news' component={News} />
            <Route path='/onlinecv' component={OnlineCv} />
            <Route path='/login-page' component={ShopLogin} />
            <PrivateRoute path='/order-now' component={OrderNow} />
            <PrivateRoute path='/order-list' component={ShopWishlist} />
            <PrivateRoute path='/orderEdit/:id' component={OrderEdit} />
            <Route path='/certificate-details' component={CertificateDetails} />
            <Route path='/growthTowardsNature' component={GrowthTowardsNature} />
            <Route path="/all-products" component={ProductsPage} />
            <Route path="/product/:id" component={ProductDetails} />
            {/* <Route path="/blog-grid-2-sidebar" component={BlogGrid2Sidebar} />
            

            <Route path="/blog-grid-2-sidebar" component={BlogGrid2Sidebar} />
            <Route
              path="/blog-grid-2-sidebar-left"
              component={BlogGrid2SidebarLeft}
            />
            <Route path="/blog-grid-3" component={BlogGrid3} />
            <Route path="/blog-grid-3-sidebar" component={BlogGrid3Sidebar} />
            <Route
              path="/blog-grid-3-sidebar-left"
              component={BlogGrid3SidebarLeft}
            />
            <Route path="/blog-grid-4" component={BlogGrid4} />
            <Route path="/blog-half-img" component={BlogHalfImg} />
            <Route
              path="/blog-half-img-left-sidebar"
              component={BlogHalfImgSidebarLeft}
            />
            <Route
              path="/blog-half-img-sidebar"
              component={BlogHalfImgSidebar}
            />
            <Route path="/blog-large-img" component={BlogLargeImg} />
            <Route
              path="/blog-large-img-left-sidebar"
              component={BlogLargeImgSidebarLeft}
            />
            <Route
              path="/blog-large-img-sidebar"
              component={BlogLargeImgSidebar}
            />
            <Route path="/blog-single" component={BlogSingle} />
            <Route
              path="/blog-single-left-sidebar"
              component={BlogSingleSidebarLeft}
            />
            <Route path="/blog-single-sidebar" component={BlogSingleSidebar} />
            <Route path="/coming-soon-1" component={ComingSoon1} />

            <Route path="/contact-2" component={Contact2} />
            <Route path="/contact-3" component={Contact3} />
            <Route path="/contact-4" component={Contact4} />
            <Route path="/error-403" component={Error403} />
            <Route path="/error-404" component={Error404} />
            <Route path="/error-405" component={Error405} />
            <Route path="/faq-1" component={Faq1} />
            <Route path="/faq-2" component={Faq2} />
            <Route path="/help-desk" component={HelpDesk} />
            <Route path="/portfolio-details" component={PortfolioDetails} />
            <Route path="/portfolio-grid-2" component={PortfolioGrid2} />
            <Route path="/portfolio-grid-3" component={PortfolioGrid3} />
            <Route path="/portfolio-grid-4" component={PortfolioGrid4} />
            <Route path="/privacy-policy" component={PrivacyPolicy} />
            <Route path="/services-1" component={Services1} />
            <Route path="/services-2" component={Services2} />
            <Route path="/services-details" component={ServicesDetails} />
            <Route path="/shop-cart" component={ShopCart} />
            <Route path="/shop-checkout" component={ShopCheckout} />
            
            <Route
              path="/shop-product-details"
              component={shopProductDetails}
            />
            
            <Route path="/shop-sidebar" component={ShopSidebar} />
            
            <Route path="/shop" component={Shop} />
            <Route path="/team-1" component={Team1} />
            <Route path="/team-2" component={Team2} />
            <Route path="/index-2" component={Index2} />
            <Route path="/index-3" component={Index3} /> */}
            {/* <Route path="/index-4" component={Index4} />
            <Route path="/index-5" component={Index5} />
            <Route path="/index-6" component={Index6} />
            <Route path="/index-7" component={Index7} />
            <Route path="/index-8" component={Index8} />
            <Route path="/index-9" component={Index9} />
            <Route path="/index-10" component={Index10} />
            <Route path="/index-11" component={Index11} />
            <Route path="/index-12" component={Index12} />
            <Route path="/index-13" component={Index13} />
            <Route path="/index-14" component={Index14} />
            <Route path="/index-15" component={Index15} />
            <Route path="/shortcode-buttons" component={shortButtons} />
            <Route path="/shortcode-alert-box" component={shortAlertBox} />
            <Route
              path="/shortcode-icon-box-styles"
              component={shortIconBoxStyles}
            />
            <Route path="/shortcode-icon-box" component={shortIconBox} />
            <Route
              path="/shortcode-pricing-table"
              component={shortPricingTable}
            />
            <Route path="/shortcode-dividers" component={shortDividers} />
            <Route path="/shortcode-list-group" component={shortListGroup} />
            <Route
              path="/shortcode-title-separators"
              component={shortTitleSeparators}
            />
            <Route
              path="/shortcode-image-box-content"
              component={shortImageBoxContent}
            />
            <Route path="/shortcode-pagination" component={shortPagination} />
            <Route path="/shortcode-counters" component={shortCounters} />
            <Route
              path="/shortcode-testimonials"
              component={shortTestimonials}
            />
            <Route
              path="/shortcode-images-effects"
              component={shortImagesEffects}
            />
            <Route path="/shortcode-shop-widgets" component={shopWidgets} />
            <Route path="/shortcode-team" component={shortTeam} /> */}
          </Switch>
        </div>
      </BrowserRouter>
    )
  }
}

export default Router

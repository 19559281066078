import Axios from 'axios'
import createAuthRefreshInterceptor from 'axios-auth-refresh'

const preBaseUrl = "prod"
const BASE_URL = preBaseUrl === "dev" ? "http://143.198.195.72:8080/cielx-0.0.1-SNAPSHOT/" : "https://rest.cielx.org/cielx-0.0.1-SNAPSHOT";

export const IMAGE_PREFIX = preBaseUrl === "dev" ? 'http://143.198.195.72:8080/abc' : "https://rest.cielx.org/abc"

const instance = Axios.create({
  baseURL: BASE_URL,
})

const client_id = 'client-rest-jsrm'
const client_secret = 'rest-secret-jsrm'

const refreshAuthLogic = (failedRequest) =>
  instance
    .post('/oauth/token', null, {
      params: {
        grant_type: 'refresh_token',
        client_id,
        client_secret,
        refresh_token: sessionStorage.getItem('refresh_token'),
      },
    })
    .then((tokenRefreshResponse) => {
      failedRequest.response.config.params['access_token'] =
        tokenRefreshResponse.data.access_token
      sessionStorage.setItem(
        'access_token',
        tokenRefreshResponse.data.access_token
      )
      sessionStorage.setItem(
        'refresh_token',
        tokenRefreshResponse.data.refresh_token
      )

      return Promise.resolve()
    })
    .catch(() => {
      // store.dispatch(logout());
      // window.location.href = "/login";
      return Promise.reject()
    })

createAuthRefreshInterceptor(instance, refreshAuthLogic)

export const login = async (data) => {
  const fd = new FormData()
  fd.append('grant_type', 'password')
  fd.append('password', data.password)
  fd.append('username', data.username)
  fd.append('client_id', client_id)
  fd.append('client_secret', client_secret)
  return await instance.post(`/oauth/token`, fd)
}

export const getHomeSlider = async () => {
  const res = await instance.get('/user/home/page/slider')
  return res.data
}

export const getAllAbout = async ({ type }) => {
  console.log('type', type)

  return await instance.get('/user/about/us/by/type', {
    params: { type },
  })
}

export const getAllDirectors = async ({ type }) => {
  return await instance.get('/user/director/by/type', {
    params: { type },
  })
}
export const getAllProducts = async () => {
  return await instance.get('/user/product', {
    params: {},
  })
}
export const getNewsAndArticles = async () => {
  return await instance.get('/user/news-and-article', {
    params: {},
  })
}
export const getEvents = async () => {
  return await instance.get('/user/news-and-publications', {
    params: {},
  })
}

export const getQualityAssurance = async ({ type }) => {
  return await instance.get('/user/quality/assurance/by/type', {
    params: { type },
  })
}
export const getServices = async ({ type }) => {
  return await instance.get('/user/services/by/type', {
    params: { type },
  })
}
export const getSustainability = async ({ type }) => {
  return await instance.get('/user/sustainability/by/type', {
    params: { type },
  })
}
export const getHRPolicy = async () => {
  return await instance.get('/user/hr-policy', {
    params: {},
  })
}
export const getJobs = async () => {
  return await instance.get('/user/career', {
    params: {},
  })
}

export const getDocuments = async ({ type }) => {
  return await instance.get('/user/documents/by/type', {
    params: { type },
  })
}
export const getTVC = async () => {
  return await instance.get('/user/commercials', {
    params: {},
  })
}
export const fileUpload = async (file, urlType) => {
  console.log('filetype', file, urlType)
  const fd = new FormData()
  fd.append('file', file)
  fd.append('urlType', urlType)
  return await instance.post('/all/upload/file', fd)
}

export const cvUpload = async (data) => {
  return await instance.post('/user/online-cv', data)
}
export const getBanners = async ({ type }) => {
  return await instance.get("/user/quality/assurance/by/type", {
    params: { type },
  });
}
export const createOrder = async (data) => {
  const access_token = localStorage.getItem('access_token')
  return await instance.post('/admin/order', data, {
    params: {
      access_token,
      client_id,
      client_secret,
    },
  })
}

export const getOrder = async (fromDate, toDate, status) => {
  const access_token = localStorage.getItem('access_token')
  return await instance.get('/admin/order/get/by/id/and/status', {
    params: {
      access_token,
      client_id,
      client_secret,
      fromDate,
      toDate,
      status,
      id: localStorage.getItem('userId'),
    },
  })
}

export const deleteOrder = async (id) => {
  const access_token = localStorage.getItem('access_token')
  return await instance.delete('/admin/order', {
    params: {
      access_token,
      client_id,
      client_secret,
      id,
    },
  })
}

export const getOrderById = async (id) => {
  const access_token = localStorage.getItem('access_token')
  return await instance.get(`/admin/order/${id}`, {
    params: {
      access_token,
      client_id,
      client_secret,
    },
  })
}

export const editOrder = async (data) => {
  const access_token = localStorage.getItem('access_token')
  return await instance.put('/admin/order', data, {
    params: {
      access_token,
      client_id,
      client_secret,
    },
  })
}

export const getTestimonials = async () => {
  return await instance.get('/user/rating')
}

export const getProductById = async (id) => {
  return await instance.get(`/user/documents/${id}`)
}
export const getCielxProducts = async ({ type }) => {
  return await instance.get('/user/documents/by/type', {
    params: { type },
  })
}
import React from "react";

export const NatureSection = () => {
    return (
        <div className="nature-container">
            <div className="div">
                <div className="text-wrapper">Nature and Sustainability</div>
                <p className="nature-provides-us">
                    Nature provides us with the resources and beauty essential for our well-being, but our use of these gifts must
                    be balanced with a commitment to sustainability. By adopting practices that protect ecosystems and reduce
                    waste, we ensure that natural landscapes can thrive and continue to support life. Sustainability is not just
                    about conserving resources; it&#39;s about nurturing the intricate web of life that sustains our planet.
                    Through mindful stewardship, we can honor nature and secure its vitality for future generations.
                </p>
            </div>
            <div className="div-2">
                <div className="flip-card">
                    <div className="flip-card-inner">
                        <div className="flip-card-front">
                            <img className="serene" alt="Serene" src={require("../../images/background/tranquility.png")} />
                            <div className="text-wrapper-2">Tranquility</div>
                        </div>
                        <div className="flip-card-back">
                            <p className="p">
                                Embracing serenity means cultivating a state of calm and tranquility, where inner peace and balanced harmony prevail. Prioritizing sustainability ensures that our actions today nurture a thriving environment for future generations, balancing growth with ecological responsibility.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="flip-card">
                    <div className="flip-card-inner">
                        <div className="flip-card-front">
                            <img className="achievement-traced" alt="Achievement traced" src={require("../../images/background/process.png")} />
                            <div className="text-wrapper-2">Harmony</div>
                        </div>
                        <div className="flip-card-back">
                            <p className="p">
                                Harmony with the natural world fosters a balanced ecosystem where every element thrives in sync. Embracing sustainability ensures that this equilibrium is preserved for future generations, creating a lasting legacy of environmental stewardship and well-being.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="flip-card">
                    <div className="flip-card-inner">
                        <div className="flip-card-front">
                            <img className="another-image" alt="Another Concept" src={require("../../images/background/success.png")} />
                            <div className="text-wrapper-2">Success</div>
                        </div>
                        <div className="flip-card-back">
                            <p className="p">
                                Success is not just about achieving immediate goals but ensuring that our methods and impacts are sustainable for the long term. True success is measured by the ability to maintain progress while preserving resources and fostering positive change for future generations.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header from '../layout/header10';
import Footer from '../layout/footer4';
import { useState } from 'react';
import { useEffect } from 'react';
import { getAllAbout, getNewsAndArticles } from '../server';
import BlogSlider1 from '../element/blogSlider1';

var bnr3 = require('./../../images/banner/bnr3.jpg');

function About2() {
  const [aboutUs, setAboutUs] = useState(null);
  const [news, setNews] = useState(null);

  useEffect(() => {
    getAllAbout({ type: 'home_page' })
      .then((res) => {
        console.log('resAboutUs', res);
        if (res) {
          setAboutUs(res?.data[0]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  useEffect(() => {
    getNewsAndArticles()
      .then((res) => {
        console.log('news', res);
        if (res) {
          setNews(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      <Header />

      <div className="page-content bg-white">
        <div
          className="dlab-bnr-inr overlay-black-middle bg-pt"
          style={{ backgroundImage: 'url(' + bnr3 + ')' }}
        >
          <div className="container">
            <div className="dlab-bnr-inr-entry">
              <h1 className="text-white">About us </h1>
              <div className="breadcrumb-row">
                <ul className="list-inline">
                  <li>
                    <Link to={'/'}>Home</Link>
                  </li>
                  <li>About us </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="content-block">
          <div className="section-full content-inner bg-white">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-6 col-md-12 m-b30">
                  <div className="our-story">
                    <span>OUR STORY</span>
                    <h2 className="title">{aboutUs?.title}</h2>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: `${aboutUs?.body}`,
                      }}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 m-b30 ">
                  <img
                    src={`http://143.198.195.72:8080/abc/${aboutUs?.image?.url}`}
                    className="radius-sm"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>

          <div
            className="section-full content-inner bg-white wow fadeIn"
            data-wow-duration="2s"
            data-wow-delay="0.6s"
          >
            <div className="container">
              <div className="section-head text-black text-center">
                <h2 className="title">News & Articles</h2>
                <p></p>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <BlogSlider1 data={news} imgEffect="rotate" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
export default About2;

import React, { Component } from "react";
import Counter from "./counter";
import ModalVideo from "react-modal-video";
import "react-modal-video/scss/modal-video.scss";

class FlipCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  render() {
    return (
      <div className="flip-card-wrapper">
        <div className="container-fluid">
          <div className="row">

            <div className="col-lg-3 col-md-6 col-sm-12 single-flip-col">
              <div className="single-flip-card">
                <div className="dm-flip-card">
                  <div className="dm-front-card dm-flip-card-3"> </div>
                  <div className="dm-back-card">
                    <img
                      src={require("../../images/background/fliphead.png")}
                      alt=""
                    />
                    <p style={{ fontFamily: "Crimson Text" }}>
                      At Cielx, we believe in the power of globalization to drive progress and innovation. By embracing the interconnectedness of global supply chains, we leverage diverse resources and expertise from around the world. This enables us to deliver superior products and services, fostering economic growth and enhancing cultural exchange. Through globalization, Cielx is committed to creating a more connected and prosperous future for all.
                    </p>
                  </div>
                </div>
                <div className="tris-no-flip-bottom-wrap">
                  <h1 className="tris-no-fli-text">Globalization</h1>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 single-flip-col">
              <div className="single-flip-card">
                <div className="dm-flip-card">
                  <div className="dm-front-card dm-flip-card-1"> </div>
                  <div className="dm-back-card">
                    <img
                      src={require("../../images/background/RawMaterial.png")}
                      alt=""
                    />
                    <p style={{ fontFamily: "Crimson Text" }}>
                      Cielx, the muse of industry, channels the heart of creation into every thread and granule. Our raw materials are the poetry of production, echoing quality and sustainability. Beyond borders, we sculpt the foundation of innovation for the RMG and FMCG sectors. With eco-conscious polybags and more, Cielx transforms raw potential into masterpieces of commerce. Trust in our legacy of excellence to inspire your journey.
                    </p>
                  </div>
                </div>
                <div className="tris-no-flip-bottom-wrap">
                  <h1 className="tris-no-fli-text">Raw Materials</h1>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 single-flip-col single-flip-col">
              <div className="single-flip-card">
                <div className="dm-flip-card">
                  <div className="dm-front-card dm-flip-card-4"> </div>
                  <div className="dm-back-card">
                    <img
                      src={require("../../images/background/Automation.png")}
                      alt=""
                    />
                    <p style={{ fontFamily: "Crimson Text" }}>
                      Amidst the whirring gears and humming circuits of Cielx's workshops, Automation paints masterpieces of optimization, brushing strokes of ingenuity onto the canvas of industry. Within the pulsating heart of Cielx's technological hub, automation stands as the harbinger of a new era, where innovation knows no bounds and efficiency reigns supreme. Here, amidst the whirlwind of technological evolution, humanity's quest for efficiency finds its ultimate expression in the seamless integration of man and machine.
                    </p>
                  </div>
                </div>
                <div className="tris-no-flip-bottom-wrap">
                  <h1 className="tris-no-fli-text">Automation</h1>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 single-flip-col">
              <div className="single-flip-card">
                <div className="dm-flip-card">
                  <div className="dm-front-card dm-flip-card-2"> </div>
                  <div className="dm-back-card">
                    <img
                      src={require("../../images/background/Traceability.png")}
                      alt=""
                    />
                    <p style={{ fontFamily: "Crimson Text" }}>
                      At Cielx, we ensure transparency and sustainability by meticulously tracking materials from sourcing to end-of-life. Using advanced data systems, we monitor every stage to optimize resource use and enable recycling or repurposing. This real-time visibility allows us to minimize waste and maximize efficiency. Through our traceability process, we uphold the highest standards of transparency and environmental responsibility.
                    </p>
                  </div>
                </div>
                <div className="tris-no-flip-bottom-wrap">
                  <h1 className="tris-no-fli-text">Traceability</h1>
                </div>
              </div>
            </div>


          </div>
        </div>
      </div>
    );
  }
}

export default FlipCard;

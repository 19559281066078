import React from "react";
import { IMAGE_PREFIX } from "../server";


const FlipCard = ({ sectionData }) => {

  return (
    <div className="flip-card-wrapper">
      <div className="container-fluid">
        <div className="row">

          {/* Globalization Flip Card */}
          <div className="col-lg-3 col-md-6 col-sm-12 single-flip-col">
            <div className="single-flip-card">
              <div className="dm-flip-card">
                <div className="dm-front-card dm-flip-card-3"
                  style={{ backgroundImage: sectionData?.boxElements[0]?.backgroundImage ? `url(${IMAGE_PREFIX}/${sectionData?.boxElements[0]?.backgroundImage?.url})` : "none" }}></div>
                <div className="dm-back-card">
                  <img src={`${IMAGE_PREFIX}/${sectionData?.boxElements[0]?.icon?.url}`} alt="Globalization" />
                  <p style={{ fontFamily: "Crimson Text" }}>
                    {sectionData?.boxElements[0]?.description}
                  </p>
                </div>
              </div>
              <div className="tris-no-flip-bottom-wrap">
                <h1 className="tris-no-fli-text">{sectionData?.boxElements[0]?.title}</h1>
              </div>
            </div>
          </div>
          {/* Raw Materials Flip Card */}
          <div className="col-lg-3 col-md-6 col-sm-12 single-flip-col">
            <div className="single-flip-card">
              <div className="dm-flip-card">
                <div className="dm-front-card dm-flip-card-3"
                  style={{ backgroundImage: sectionData?.boxElements[1]?.backgroundImage ? `url(${IMAGE_PREFIX}/${sectionData?.boxElements[1]?.backgroundImage?.url})` : "none" }}></div>
                <div className="dm-back-card">
                  <img src={`${IMAGE_PREFIX}/${sectionData?.boxElements[1]?.icon?.url}`} alt="Raw Materials" />
                  <p style={{ fontFamily: "Crimson Text" }}>
                    {sectionData?.boxElements[1]?.description}
                  </p>
                </div>
              </div>
              <div className="tris-no-flip-bottom-wrap">
                <h1 className="tris-no-fli-text">{sectionData?.boxElements[1]?.title}</h1>
              </div>
            </div>
          </div>
          {/* Automation Flip Card */}
          <div className="col-lg-3 col-md-6 col-sm-12 single-flip-col">
            <div className="single-flip-card">
              <div className="dm-flip-card">
                <div className="dm-front-card dm-flip-card-3"
                  style={{ backgroundImage: sectionData?.boxElements[2]?.backgroundImage ? `url(${IMAGE_PREFIX}/${sectionData?.boxElements[2]?.backgroundImage?.url})` : "none" }}></div>
                <div className="dm-back-card">
                  <img src={`${IMAGE_PREFIX}/${sectionData?.boxElements[2]?.icon?.url}`} alt="Automation" />
                  <p style={{ fontFamily: "Crimson Text" }}>
                    {sectionData?.boxElements[2]?.description}
                  </p>
                </div>
              </div>
              <div className="tris-no-flip-bottom-wrap">
                <h1 className="tris-no-fli-text">{sectionData?.boxElements[2]?.title}</h1>
              </div>
            </div>
          </div>
          {/* Traceability Flip Card */}
          <div className="col-lg-3 col-md-6 col-sm-12 single-flip-col">
            <div className="single-flip-card">
              <div className="dm-flip-card">
                <div className="dm-front-card dm-flip-card-3"
                  style={{ backgroundImage: sectionData?.boxElements[3]?.backgroundImage ? `url(${IMAGE_PREFIX}/${sectionData?.boxElements[3]?.backgroundImage?.url})` : "none" }}></div>
                <div className="dm-back-card">
                  <img src={`${IMAGE_PREFIX}/${sectionData?.boxElements[3]?.icon?.url}`} alt="Traceability" />
                  <p style={{ fontFamily: "Crimson Text" }}>
                    {sectionData?.boxElements[3]?.description}
                  </p>
                </div>
              </div>
              <div className="tris-no-flip-bottom-wrap">
                <h1 className="tris-no-fli-text">{sectionData?.boxElements[3]?.title}</h1>
              </div>
            </div>
          </div>




        </div>
      </div>
    </div>
  );
};

export default FlipCard;

// import React from 'react';
import React, { Component } from "react";
import "./App.css";
import Router from "./markup/router";

import "./css/plugins.css";
import "./css/style.css";
import "./plugins/flaticon/beer/flaticon.css";
import "./plugins/flaticon/flaticon.css";
import "./plugins/fontawesome/css/font-awesome.min.css";
import "./plugins/line-awesome/css/line-awesome.min.css";

import "./css/homepage-styles.css";
import "./css/templete.min.css";

// homepage-styles.css

class App extends Component {
  render() {
    return (
      <div className="App">
        <Router />
      </div>
    );
  }
}

export default App;

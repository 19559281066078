import emailjs from "emailjs-com";
import React, { useEffect, useState } from "react";
import { getContactUs, IMAGE_PREFIX } from "../server";

const bg = require("../../images/background/bg3.png");
// style="background-image:url(images/background/bg3.png); background-size: cover;"

const FooterHome = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // Initial state
  const [footerData, setFooterData] = useState(null);
  const fetchSectionData = () => {
    getContactUs()
      .then((res) => {
        console.log("footer section :", res?.data[0]);
        if (res) {
          setFooterData(res?.data[0]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // Update the isMobile state on window resize
  const updateWindowDimensions = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    fetchSectionData();
    // Add event listener to handle resize
    window.addEventListener("resize", updateWindowDimensions);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", updateWindowDimensions);
    };
  }, []);

  const sendEmail = (e) => {
    e.preventDefault();

    //emailjs.sendForm('YOUR_SERVICE_ID', 'YOUR_TEMPLATE_ID', form.current, 'YOUR_USER_ID')
    emailjs
      .sendForm(
        "YOUR_SERVICE_ID",
        "YOUR_TEMPLATE_ID",
        e.target,
        "d9b2e0f5fc72cb94792110e8ff2028f3-us16"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  };
  return (
    <>
      <footer className="site-footer" id="footer">
        <div className="footer-top-custom">
          <div className="cielx-new-container" style={{ margin: "0px auto" }}>
            <div>
              {/* <img
                src={require("../../images/background/logo-footer.png")}
                style={{ width: 200 }}
              /> */}
              <div className="contact-page-header">
                <div>
                  <h3 className="mt-0">Our Offices</h3>
                </div>
              </div>
            </div>

            <div className="row d-flex justify-content-center">
              {footerData?.footerElements?.map((element, index) => {
                return (
                  <div
                    className={`col-lg-3 justify-content-between${
                      isMobile
                        ? " single-footer-column4"
                        : " single-footer-column"
                    }`}
                    key={index}
                  >
                    <div className="col-sm-12" style={{ marginTop: 40 }}>
                      <div
                        className="icon-text-container"
                        style={{
                          justifyContent: "center",
                          marginBottom: 34,
                        }}
                      >
                        <div style={{ width: 24, height: 24 }}>
                          <img
                            src={`${IMAGE_PREFIX}/${element?.flagIcon?.url}`}
                          />
                        </div>
                        <div>
                          <a href="#footer">{element?.countryName}</a>
                        </div>
                      </div>

                      <div className="icon-text-container">
                        <div style={{ width: 24, height: 24 }}>
                          <img
                            src={require("../../images/background/smallPhone.png")}
                          />
                        </div>
                        <div>
                          <a href={`tel:${element?.phone}`}>{element?.phone}</a>
                        </div>
                      </div>

                      <div className="icon-text-container">
                        <div style={{ width: 24, height: 24 }}>
                          <img
                            src={require("../../images/background/smallMail.png")}
                          />
                        </div>
                        <div>
                          <a href={`mailto:${element?.email}`}>
                            {element?.email}
                          </a>
                        </div>
                      </div>

                      <div className="icon-text-container">
                        <img
                          src={require("../../images/background/smallLocation2.png")}
                          width={24}
                          height={24}
                        />
                        <a href={element?.addressLink}>{element?.address}</a>
                      </div>
                    </div>
                  </div>
                );
              })}

              {/* <div className="col-lg-3">
                <div
                  className={
                    isMobile ? "single-footer-column4" : "single-footer-column"
                  }
                  style={{ marginTop: 40 }}
                >
                  <div
                    className="icon-text-container"
                    style={{ justifyContent: "center", marginBottom: 34 }}
                  >
                    <div style={{ width: 24, height: 24 }}>
                      <img
                        src={`${IMAGE_PREFIX}/${footerData?.footerElements[1]?.flagIcon?.url}`}
                      />
                    </div>
                    <div>
                      <a href="#footer">
                        {footerData?.footerElements[1]?.countryName}
                      </a>
                    </div>
                  </div>

                  <div className="icon-text-container">
                    <div style={{ width: 15, height: 27 }}>
                      <img
                        src={require("../../images/background/smallPhone.png")}
                      />
                    </div>
                    <div>
                      <a href={`tel:${footerData?.footerElements[1]?.phone}`}>
                        {footerData?.footerElements[1]?.phone}
                      </a>
                    </div>
                  </div>

                  <div className="icon-text-container">
                    <div style={{ width: 24, height: 24 }}>
                      <img
                        src={require("../../images/background/smallMail.png")}
                      />
                    </div>
                    <div>
                      <a
                        href={`mailto:${footerData?.footerElements[1]?.email}`}
                      >
                        {footerData?.footerElements[1]?.email}
                      </a>
                    </div>
                  </div>

                  <div className="icon-text-container">
                    <img
                      src={require("../../images/background/smallLocation2.png")}
                      width={24}
                      height={24}
                    />
                    <a href={footerData?.footerElements[1]?.addressLink}>
                      {footerData?.footerElements[1]?.address}
                    </a>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>

        <div
          className="footer-bottom"
          style={{ height: 54, display: "flex", alignItems: "center" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-sm-12 text-center">
                {" "}
                <span className="footer-last-text">
                  {" "}
                  {new Date().getFullYear()} by CIELX, All rights reserved.
                </span>{" "}
              </div>
              {/* <div className="col-md-6 col-sm-6 text-right ">
                {" "}
                <span className="footer-last-text">Developed by Sweet iTech Team</span>{" "}
              </div> */}
              {/* <div className="col-md-6 col-sm-6 text-right ">
                <div className="widget-link ">
                  <ul>
                    <li>
                      <Link to=""> About</Link>
                    </li>
                    <li>
                      <Link to=""> Help Desk</Link>
                    </li>
                    <li>
                      <Link to=""> Privacy Policy</Link>
                    </li>
                  </ul>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};
export default FooterHome;

import React, { useEffect, useState } from "react";
import HomeSlider2 from "../../element/homeSlider2";
import { homeSliderContent8 } from "../../element/SliderContent";
import Header from "../../layout/header10";
import { getBanners, getSustainability, getTestimonials, IMAGE_PREFIX } from "../../server";
import CommonBannerSlider from "../../element/CommonBannerSlider";
import FooterHome from "../../layout/footerHome";
import ScrollToTop from "../../element/scrollToTop";
import { FaStar } from "react-icons/fa";
const TestimonialDetails = () => {
    const [banner, setBanner] = useState(null);
    const [testimonials, setTestimonials] = useState(null);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // Track if mobile view
    useEffect(() => {
        // Check if screen size is mobile and update state
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);
    useEffect(() => {
        getBanners({ type: "TESTIMONAL" })
            .then((res) => {
                console.log("resAboutUs", res);
                if (res) {
                    setBanner(res?.data[0]);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    useEffect(() => {
        getTestimonials()
            .then((res) => {
                console.log("res", res);
                if (res) {
                    setTestimonials(res?.data);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    return (
        <div>
            <Header />
            <div className="page-content home-slider-10" style={{ backgroundColor: "#E1E1E1", paddingBottom: 0 }}>
                <CommonBannerSlider
                    data={banner}
                    contentWrapperCls="sliderStyle10"
                    subTitleCls="text-primary"
                    btnCls="text-primary"
                    btnText="Continue The Journey"
                />

                <div className="cielx-testimonial-container">
                    <div className="cielx-testimonial-content" style={{ marginTop: 60, marginBottom: 60 }}>
                        <div className="cielx-testimonial-heading">What Our Clients Said About Us</div>
                        <div className="col-12 row">

                            {testimonials ? (
                                <>
                                    {testimonials.map((certificate, index) => {
                                        // Only render when index is even
                                        if (index % 2 === 0) {
                                            return (
                                                <div key={index} className="cielx-testimonial-box">
                                                    <div
                                                        className="cielx-testimonial-card"
                                                        style={{ marginBottom: isMobile ? 0 : 60 }}
                                                    >
                                                        <div className="cielx-testimonial-card-content">
                                                            <div className="star-desc">
                                                                {Array.from({ length: testimonials[index].rating }, (_, i) => (
                                                                    <FaStar key={i} color="gold" />
                                                                ))}
                                                                <div className="alignStretch">

                                                                    <p className="textStyle">
                                                                        {testimonials[index].details}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="cielx-testimonial-card-content2">
                                                            <div className="cielx-testimonial-card-name">
                                                                <div className="name-designation">
                                                                    <div style={{ alignSelf: "stretch" }}>
                                                                        <p className="name-text">{testimonials[index].name}</p>
                                                                    </div>

                                                                    <p className="designation-text">{testimonials[index].designation}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {testimonials[index + 1] && (
                                                        <div
                                                            className="cielx-testimonial-card"
                                                            style={{ marginTop: isMobile ? 0 : 60 }}
                                                        >
                                                            <div className="cielx-testimonial-card-content">
                                                                <div className="star-desc">
                                                                    {Array.from({ length: testimonials[index + 1].rating }, (_, i) => (
                                                                        <FaStar key={i} color="gold" />
                                                                    ))}
                                                                    <div className="alignStretch">
                                                                        <p className="textStyle" >
                                                                            {testimonials[index + 1].details}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="cielx-testimonial-card-content2">
                                                                <div className="cielx-testimonial-card-name">
                                                                    <div className="name-designation">
                                                                        <div style={{ alignSelf: "stretch" }}>
                                                                            <p className="name-text">{testimonials[index + 1].name}</p>
                                                                        </div>

                                                                        <p className="designation-text">{testimonials[index + 1].designation}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            );
                                        }
                                        return null; // Skip odd indices
                                    })}
                                </>
                            ) : null}

                        </div>

                    </div>
                </div>


            </div>
            <FooterHome />
            <ScrollToTop className='style3' />
        </div>
    );
};

export default TestimonialDetails;

import React, { useEffect, useRef } from "react";
import { IMAGE_PREFIX } from "../../server";

const FutureDivReverse = ({ sectionData }) => {
  const sectionRef = useRef(null);
  const imgUrl = "../../../images/background/tree1.png";
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            // Add 'show' class when the section is in view
            entry.target.classList.add("show");
          } else {
            // Optional: remove 'show' class when the section is out of view
            entry.target.classList.remove("show");
          }
        });
      },
      {
        threshold: 0.1, // Trigger when 10% of the section is visible
      }
    );

    const elements = sectionRef.current.querySelectorAll(".col-7, .col-4");

    elements.forEach((el) => observer.observe(el));

    // Clean up the observer when the component unmounts
    return () => {
      elements.forEach((el) => observer.unobserve(el));
    };
  }, []);

  return (
    <>
      <div className=" future-section" ref={sectionRef}>
        <div className="">
          <div class="image-container-reverse-future-page">
            {/* <img src={`${IMAGE_PREFIX}/${sectionData?.images[0]?.url}`} alt={sectionData?.mainTitle} class="image-1" /> */}
            <img
              src={`${IMAGE_PREFIX}/${sectionData?.backgroundImage?.url}`}
              alt={sectionData?.mainTitle}
              class="image-2"
            />
          </div>
        </div>

        <div
          className=""
          dangerouslySetInnerHTML={{ __html: sectionData?.description }}
        >
          {/* <p>
            {sectionData?.description1}
            <br />
            <br />
            {sectionData?.description2}
          </p> */}
        </div>
      </div>
    </>
  );
};

export default FutureDivReverse;

import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./BannerSlider.css";
import { IMAGE_PREFIX } from "../../server";

const BannerSlider = ({ slides }) => {
    const settings = {
        dots: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        speed: 2000,
        arrows: true,
    };

    return (
        <div className="banner-slider">
            <Slider {...settings}>
                {slides?.images?.map((slide, index) => (
                    <div key={index} className="banner-slide">
                        <img src={`${IMAGE_PREFIX}/${slide?.url}`} alt={slide.title} className="banner-image" />
                        <div className="banner-overlay">
                            {/* <div className="banner-title">{slides.title}</div>
                            <div className="banner-description">{slides.body}</div> */}
                        </div>
                    </div>
                ))}
            </Slider>
        </div>
    );
};

export default BannerSlider;

import React, { useEffect, useState } from "react";
import HomeSlider2 from "../../element/homeSlider2";
import { homeSliderContent8 } from "../../element/SliderContent";
import Header from "../../layout/header10";
import { getBanners, getSustainability, IMAGE_PREFIX } from "../../server";
import CommonBannerSlider from "../../element/CommonBannerSlider";
import FooterHome from "../../layout/footerHome";
import ScrollToTop from "../../element/scrollToTop";

const CertificateDetails = () => {
  const [banner, setBanner] = useState(null);
  const [certificates, setCertificates] = useState(null);
  useEffect(() => {
    getBanners({ type: "CERTIFICATES" })
      .then((res) => {
        console.log("resAboutUs", res);
        if (res) {
          setBanner(res?.data[0]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    getSustainability({ type: "environmental" })
      .then((res) => {
        console.log("res", res);
        if (res) {
          setCertificates(res?.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div>
      <Header />
      <div className="page-content home-slider-10" style={{ backgroundColor: "#E1E1E1", paddingBottom: 0 }}>
        <CommonBannerSlider
          data={banner}
          contentWrapperCls="sliderStyle10"
          subTitleCls="text-primary"
          btnCls="text-primary"
          btnText="Continue The Journey"
        />
        <div className="details-wrapper">
          <div className="certificate-intro">
            <h2>What Certifications Signify</h2>
            <div>
              <p style={{ fontFamily: "Crimson Text", fontSize: 22 }}>
                Our team of experts creates and designs products that follow
                lofty standards, making them a go-to name in international
                markets. Our products are ingrained with three qualities –
                creativity, durability and sustainability, all blended together
                to generate a soothing experience.{" "}
              </p>
            </div>
            <div>
              <p style={{ fontFamily: "Crimson Text", fontSize: 22 }}>
                Our team of experts creates and designs products that follow
                lofty standards, making them a go-to name in international
                markets. Our products are ingrained with three qualities –
                creativity, durability and sustainability, all blended together
                to generate a soothing experience. We strictly comply with
                environmental safety regulations through our 100% eco-friendly
                products and materials.
              </p>
            </div>
            <h2>Rigorous Process</h2>
            <div>
              <p style={{ fontFamily: "Crimson Text", fontSize: 22 }}>
                Our team of experts creates and designs products that follow
                lofty standards, making them a go-to name in international
                markets. Our products are ingrained with three qualities –
                creativity, durability and sustainability, all blended together
                to generate a soothing experience.{" "}
              </p>
            </div>
            <div>
              <p style={{ fontFamily: "Crimson Text", fontSize: 22 }}>
                Our team of experts creates and designs products that follow
                lofty standards, making them a go-to name in international
                markets. Our products are ingrained with three qualities –
                creativity, durability and sustainability, all blended together
                to generate a soothing experience. We strictly comply with
                environmental safety regulations through our 100% eco-friendly
                products and materials.
              </p>
            </div>
          </div>
          {certificates ? <div className="certificate-grid">
            {certificates?.map((certificate, index) => (
              <div key={index} className="certificate-card">
                <div className="certificate-image-container">
                  <img
                    src={`${IMAGE_PREFIX}/${certificate?.image?.url}`}
                    alt={certificate.title}
                    className="certificate-image"
                  />
                </div>
                <p className="certificate-description"><div
                  dangerouslySetInnerHTML={{
                    __html: certificate.body,
                  }}

                ></div></p>

              </div>
            ))}
          </div> : null}


        </div>
        <div className="certificate-footer">
          <img
            src={require("../../../images/background/certificateLogo.png")} // Replace with your logo URL
            alt="Logo"
            className="certificate-footer-logo"
          />
          <h2 className="certificate-footer-header">Making a Brand name</h2>
          <p className="certificate-footer-paragraph">
            Our inspiration lies in our diligence to capture the bigger picture.
            We exercise a company culture that cultivates four inspirations -
            credibility, knowledgeability, competitivity and efficiency. These
            intrinsic values speak highly of our ethics, craft, professionalism
            and management. And the certificates we hold provide us with the
            credibility, reliability and authenticity we need to march forward
            for one name, one image, one brand - J'adoube.
          </p>
        </div>
      </div>
      <FooterHome />
      <ScrollToTop className='style3' />
    </div>
  );
};

export default CertificateDetails;

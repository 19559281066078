import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const isAuthinticated = () => {
  const token = localStorage.getItem('access_token');
  try {
    if (token) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

function PrivateRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthinticated() ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/login-page',
            }}
          />
        )
      }
    />
  );
}
export default PrivateRoute;

import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const ReadMore = ({ text }) => {
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  return (
    <p className="testimonials__quote__text">
      {isReadMore ? (
        <div
          dangerouslySetInnerHTML={{
            __html: text.slice(0, 220),
          }}
        />
      ) : (
        <div
          dangerouslySetInnerHTML={{
            __html: text,
          }}
        />
      )}

      {text.length > 150 && (
        <Link to={'#'} className="site-button-link" onClick={toggleReadMore}>
          {isReadMore ? '...Read More' : '...Show Less'}
        </Link>
      )}
    </p>
  );
};

export default ReadMore;

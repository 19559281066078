export const blogContent1 = [
  {
    image: require('../../images/blog/grid/pic1.jpg'),
    title: 'Seven Doubts You Should',
    description:
      'All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.',
    author: 'Jack',
  },
  {
    image: require('../../images/blog/grid/pic2.jpg'),
    title: 'Seven Doubts You Should',
    description:
      'All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.',
    author: 'Jack',
  },
  {
    image: require('../../images/blog/grid/pic3.jpg'),
    title: 'Seven Doubts You Should',
    description:
      'All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.',
    author: 'Jack',
  },
  {
    image: require('../../images/blog/grid/pic1.jpg'),
    title: 'Seven Doubts You Should',
    description:
      'All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.',
    author: 'Jack',
  },
  {
    image: require('../../images/blog/grid/pic2.jpg'),
    title: 'Seven Doubts You Should',
    description:
      'All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.',
    author: 'Jack',
  },
  {
    image: require('../../images/blog/grid/pic3.jpg'),
    title: 'Seven Doubts You Should',
    description:
      'All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.',
    author: 'Jack',
  },
]

export const blogContent2 = [
  {
    image: require('../../images/our-services/ship/pic1.jpg'),
    title: 'Seven Doubts You Should',
    author: 'Jack',
  },
  {
    image: require('../../images/our-services/ship/pic1.jpg'),
    title: 'Seven Doubts You Should',
    author: 'Jack',
  },
  {
    image: require('../../images/our-services/ship/pic2.jpg'),
    title: 'Seven Doubts You Should',
    author: 'Jack',
  },
  {
    image: require('../../images/our-services/ship/pic3.jpg'),
    title: 'Seven Doubts You Should',
    author: 'Jack',
  },
  {
    image: require('../../images/our-services/ship/pic2.jpg'),
    title: 'Seven Doubts You Should',
    author: 'Jack',
  },
]

export const blogContent3 = [
  {
    image: require('../../images/blog/grid/pic1.jpg'),
    title: 'Seven Doubts You Should',
    description:
      'All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.',
    author: 'Jack',
  },
  {
    image: require('../../images/blog/grid/pic2.jpg'),
    title: 'Seven Doubts You Should',
    description:
      'All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.',
    author: 'Jack',
  },
  {
    image: require('../../images/blog/grid/pic3.jpg'),
    title: 'Seven Doubts You Should',
    description:
      'All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.',
    author: 'Jack',
  },
  {
    image: require('../../images/blog/grid/pic1.jpg'),
    title: 'Seven Doubts You Should',
    description:
      'All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.',
    author: 'Jack',
  },
  {
    image: require('../../images/blog/grid/pic2.jpg'),
    title: 'Seven Doubts You Should',
    description:
      'All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.',
    author: 'Jack',
  },
  {
    image: require('../../images/blog/grid/pic3.jpg'),
    title: 'Seven Doubts You Should',
    description:
      'All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.',
    author: 'Jack',
  },
]

export const blogContent4 = [
  {
    image: require('../../images/our-work/leather/pic1.jpg'),
    title: 'Seven Doubts You Should',
    description:
      'All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.',
    author: 'Jack',
  },
  {
    image: require('../../images/our-work/leather/pic1.jpg'),
    title: 'Seven Doubts You Should',
    author: 'Jack',
  },
  {
    image: require('../../images/our-work/leather/pic2.jpg'),
    title: 'Seven Doubts You Should',
    author: 'Jack',
  },
  {
    image: require('../../images/our-work/leather/pic3.jpg'),
    title: 'Seven Doubts You Should',
    author: 'Jack',
  },
  {
    image: require('../../images/our-work/leather/pic2.jpg'),
    title: 'Seven Doubts You Should',
    author: 'Jack',
  },
]

export const blogContent5 = [
  {
    image: require('../../images/our-work/nuclear/pic1.jpg'),
    title: 'Why You Should Not Go To Industry',
    author: 'Jack',
  },
  {
    image: require('../../images/our-work/nuclear/pic2.jpg'),
    title: 'Seven Doubts You Should Clarify About',
    author: 'Jack',
  },
  {
    image: require('../../images/our-work/nuclear/pic3.jpg'),
    title: 'Seven Doubts You Should',
    author: 'Jack',
  },
  {
    image: require('../../images/our-work/nuclear/pic4.jpg'),
    title: 'Seven Doubts You Should Clarify About',
    author: 'Jack',
  },
]

export const blogContent6 = [
  {
    image: require('../../images/our-work/beer/pic1.jpg'),
    title: 'Why You Should Not Go To Industry',
    description:
      'All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.',
    author: 'Jack',
  },
  {
    image: require('../../images/our-work/beer/pic2.jpg'),
    title: 'Seven Doubts You Should Clarify About',
    description:
      'All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.',
    author: 'Jack',
  },
  {
    image: require('../../images/our-work/beer/pic3.jpg'),
    title: 'Seven Doubts You Should',
    description:
      'All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.',
    author: 'Jack',
  },
  {
    image: require('../../images/our-work/beer/pic2.jpg'),
    title: 'Seven Doubts You Should Clarify About',
    description:
      'All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.',
    author: 'Jack',
  },
]

export const blogContent7 = [
  {
    image: require('../../images/our-work/car/pic1.jpg'),
    title: 'Seven Doubts You Should',
    description:
      'All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.',
    author: 'Jack',
  },
  {
    image: require('../../images/our-work/car/pic2.jpg'),
    title: 'Seven Doubts You Should',
    description:
      'All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.',
    author: 'Jack',
  },
  {
    image: require('../../images/our-work/car/pic3.jpg'),
    title: 'Seven Doubts You Should',
    description:
      'All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.',
    author: 'Jack',
  },
  {
    image: require('../../images/our-work/car/pic1.jpg'),
    title: 'Seven Doubts You Should',
    description:
      'All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.',
    author: 'Jack',
  },
  {
    image: require('../../images/our-work/car/pic2.jpg'),
    title: 'Seven Doubts You Should',
    description:
      'All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.',
    author: 'Jack',
  },
  {
    image: require('../../images/our-work/car/pic3.jpg'),
    title: 'Seven Doubts You Should',
    description:
      'All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.',
    author: 'Jack',
  },
]

export const blogContent8 = [
  {
    image: require('../../images/our-work/mining/pic1.jpg'),
    title: 'Seven Doubts You Should',
    description:
      'All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.',
    author: 'Jack',
  },
  {
    image: require('../../images/our-work/mining/pic2.jpg'),
    title: 'Seven Doubts You Should',
    description:
      'All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.',
    author: 'Jack',
  },
  {
    image: require('../../images/our-work/mining/pic3.jpg'),
    title: 'Seven Doubts You Should',
    description:
      'All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.',
    author: 'Jack',
  },
  {
    image: require('../../images/our-work/mining/pic1.jpg'),
    title: 'Seven Doubts You Should',
    description:
      'All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.',
    author: 'Jack',
  },
  {
    image: require('../../images/our-work/mining/pic2.jpg'),
    title: 'Seven Doubts You Should',
    description:
      'All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.',
    author: 'Jack',
  },
  {
    image: require('../../images/our-work/mining/pic3.jpg'),
    title: 'Seven Doubts You Should',
    description:
      'All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.',
    author: 'Jack',
  },
]

export const blogContent9 = [
  {
    image: require('../../images/our-work/plastic/pic1.jpg'),
    title: 'Why You Should Not Go To Industry',
    description:
      'All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.',
    author: 'Jack',
  },
  {
    image: require('../../images/our-work/plastic/pic2.jpg'),
    title: 'Seven Doubts You Should Clarify About',
    author: 'Jack',
  },
  {
    image: require('../../images/our-work/plastic/pic3.jpg'),
    title: 'Seven Doubts You Should',
    author: 'Jack',
  },
  {
    image: require('../../images/our-work/plastic/pic4.jpg'),
    title: 'Seven Doubts You Should Clarify About',
    author: 'Jack',
  },
]

export const ExibitionContent1 = [
  {
    image: require('./../../images/gallery/food/pic1.jpg'),
  },
  {
    image: require('./../../images/gallery/food/pic2.jpg'),
  },
  {
    image: require('./../../images/gallery/food/pic3.jpg'),
  },
  {
    image: require('./../../images/gallery/food/pic4.jpg'),
  },
]

export const exibitionContent2 = [
  {
    image: require('./../../images/gallery/leather/pic1.jpg'),
  },
  {
    image: require('./../../images/gallery/leather/pic2.jpg'),
  },
  {
    image: require('./../../images/gallery/leather/pic3.jpg'),
  },
  {
    image: require('./../../images/gallery/leather/pic4.jpg'),
  },
]

export const projectContent1 = [
  {
    image: require('../../images/our-work/steelplant/pic10.jpg'),
    projectName: 'Manufacturing',
  },
  {
    image: require('../../images/our-work/steelplant/pic9.jpg'),
    projectName: 'Iron Making',
  },
  {
    image: require('../../images/our-work/steelplant/pic8.jpg'),
    projectName: 'Steel Pipes',
  },
  {
    image: require('../../images/our-work/steelplant/pic7.jpg'),
    projectName: 'Structural Steel',
  },
  {
    image: require('../../images/our-work/steelplant/pic10.jpg'),
    projectName: 'Manufacturing',
  },
]

export const projectContent2 = [
  {
    image: require('../../images/our-work/solarplant/pic1.jpg'),
    projectName: 'Manufacturing',
  },
  {
    image: require('../../images/our-work/solarplant/pic2.jpg'),
    projectName: 'Iron Making',
  },
  {
    image: require('../../images/our-work/solarplant/pic3.jpg'),
    projectName: 'Steel Pipes',
  },
  {
    image: require('../../images/our-work/solarplant/pic4.jpg'),
    projectName: 'Structural Steel',
  },
  {
    image: require('../../images/our-work/solarplant/pic3.jpg'),
    projectName: 'Steel Pipes',
  },
  {
    image: require('../../images/our-work/solarplant/pic4.jpg'),
    projectName: 'Structural Steel',
  },
]

export const servicesContent1 = [
  {
    serviceName: 'Wind Turbines',
    icon: 'flaticon-worker',
  },
  {
    serviceName: 'Solar Panels',
    icon: 'flaticon-factory',
  },
  {
    serviceName: 'Maintenance',
    icon: 'flaticon-settings',
  },
  {
    serviceName: 'Maintenance',
    icon: 'flaticon-engineer-1',
  },
]

export const serviceContent2 = [
  {
    serviceName: 'Material Engineering',
    icon: 'flaticon-worker',
  },
  {
    serviceName: 'Power and Energy',
    icon: 'flaticon-factory',
  },
  {
    serviceName: 'Agricultural Engineering',
    icon: 'flaticon-settings',
  },
  {
    serviceName: 'Petroleum Engineering',
    icon: 'flaticon-engineer-1',
  },
  {
    serviceName: 'Material Engineering',
    icon: 'flaticon-worker',
  },
]

export const servicesContent3 = [
  {
    image: require('../../images/our-work/solarplant/pic1.jpg'),
    description:
      'All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.',
    serviceName: 'Wind Turbines',
  },
  {
    image: require('../../images/our-work/solarplant/pic2.jpg'),
    description:
      'All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.',
    serviceName: 'Solar Panels',
  },
  {
    image: require('../../images/our-work/solarplant/pic3.jpg'),
    description:
      'All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.',
    serviceName: 'Maintenance',
  },
  {
    image: require('../../images/our-work/solarplant/pic2.jpg'),
    description:
      'All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.',
    serviceName: 'Solar Panels',
  },
  {
    image: require('../../images/our-work/solarplant/pic3.jpg'),
    description:
      'All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.',
    serviceName: 'Maintenance',
  },
]

export const servicesContent4 = [
  {
    image: require('../../images/our-services/pic1.jpg'),
    description:
      'All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.',
    serviceName: 'Wind Turbines',
  },
  {
    image: require('../../images/our-services/pic2.jpg'),
    description:
      'All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.',
    serviceName: 'Solar Panels',
  },
  {
    image: require('../../images/our-services/pic3.jpg'),
    description:
      'All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.',
    serviceName: 'Maintenance',
  },
  {
    image: require('../../images/our-services/pic1.jpg'),
    description:
      'All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.',
    serviceName: 'Wind Turbines',
  },
]

export const homeSliderContent2 = [
  {
    image: require('../../images/main-slider/slide4.jpg'),
    title: 'GAS INDUSTRY',
    description:
      'Industry is ready to help you in making unique-looking and best website in a moment.',
  },
  {
    image: require('../../images/main-slider/slide5.jpg'),
    title: 'OIL INDUSTRY',
    description:
      'Industry is ready to help you in making unique-looking and best website in a moment.',
  },
]

export const homeSliderContent3 = [
  {
    image: require('../../images/main-slider/slide21.jpg'),
    title: 'Economy Needs A Healthy Steel Industry',
  },
  {
    image: require('../../images/main-slider/slide22.jpg'),
    title: 'Economy Needs A Healthy Steel Industry',
  },
]

export const homeSliderContent4 = [
  {
    image: require('../../images/main-slider/slide1.jpg'),
    title: 'Fast and Reliable Electrical services',
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
  },
  {
    image: require('../../images/main-slider/slide2.jpg'),
    title: 'Fast and Reliable Electrical services',
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
  },
]

export const homeSliderContent5 = [
  {
    image: require('../../images/main-slider/slide9.jpg'),
    title: 'WE BUILD YOUR DREAM',
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
  },
  {
    image: require('../../images/main-slider/slide10.jpg'),
    title: 'WE ARE CONSTRUCT',
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
  },
]

export const homeSliderContent6 = [
  {
    image: require('../../images/main-slider/slide11.jpg'),
    title: 'Solar Wind Power Technology ',
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
  },
  {
    image: require('../../images/main-slider/slide12.jpg'),
    title: 'Solar Wind Power Technology ',
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
  },
]

export const homeSliderContent7 = [
  {
    subTitle: 'Our Latest Item Food Industry',
    image: require('../../images/main-slider/slide13.jpg'),
    title: 'Our Latest Item Food Industry ',
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
  },
  {
    subTitle: 'Our Latest Item Food Industry',
    image: require('../../images/main-slider/slide14.jpg'),
    title: 'Our Latest Item Food Industry ',
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
  },
]

export const homeSliderContent8 = [
  {
    subTitle: "INVESTMENT OPPORTUNITIES IN CIELX",
    image: require("../../images/main-slider/homeSlide.jpg"),
    title: "Welcome To CIELX",
  },
  {
    subTitle: "INVESTMENT OPPORTUNITIES IN CIELX",
    image: require("../../images/main-slider/slide24.jpg"),
    title: "Welcome To CIELX",
  },
];

export const homeSliderContent9 = [
  {
    subTitle: 'INVESTMENT OPPORTUNITIES IN LEATHER',
    image: require('../../images/main-slider/slide23.jpg'),
    title: 'Nuclear Power Plants',
    description: 'Great Lessons You Can Learn From Nuclear Plant',
  },
]

export const homeSliderContent10 = [
  {
    subTitle2: 'Sale Car – Buy Car – Car Service',
    image: require('../../images/main-slider/slide29.jpg'),
    title: 'Auto Mobile Industry',
    description:
      ' With 19+ years’ experience in combining traditions and innovations, Arty creates settings to inspire the way people live, learn, heal, work and play.',
  },
]

export const homeSliderContent11 = [
  {
    subTitle: 'Value Creation Form Waste ',
    image: require('../../images/main-slider/slide19.jpg'),
    title: 'Plastic Waste Managment & Recycling',
    description: 'Powering Progress Through Plastic',
  },
]

export const aboutSectionReverseContent = [
  {
    title: 'Growth Towards Nature',
    image: require('../../images/background/homeGrowth.png'),
    description1:
      "Sustainability today is often viewed as a balance where we meet present needs while ensuring that future generations have the resources and opportunities they need to thrive. It’s about creating a positive legacy rather than depleting resources. This holistic approach is reflected in various aspects of modern life, including how companies like Cielx operate by supporting nature and sustainable growth.",
    description2:
      'Because sustainability is not compromising the present for the future; rather, it facilitates the present without compromising the future.',
    btnText: 'Learn More',
    btnLink: '/growthTowardsNature',
  },
]
export const aboutSectionContentFirst = [
  {
    title: 'About Us',
    image: require('../../images/background/homeAbout.png'),
    description1:
      'Introducing Cielx, a pioneering trading company based in Bangladesh, dedicated to revolutionizing the global supply chain landscape through its focus on globalization, traceability, raw materials, and automation.',
    description2:
      'At Cielx, we serve as a trusted partner, providing essential raw material support to the Ready-Made Garments (RMG) sector, as well as supplying a diverse range of materials for industries such as plastic, paper, ribbon, and yarn.',
    btnText: 'Learn More',
    btnLink: '/about-us',
  },
]
export const aboutUsPageAboutSection = [
  {
    title: 'WHO WE ARE',
    image: require('../../images/background/aboutus1.png'),
    description1:
      `Located strategically in Bangladesh, Cielx operates at the crossroads of international trade, collaborating seamlessly with a myriad of esteemed European and North American brands, alongside RMG packaging manufacturers within Bangladesh. Our extensive network and expertise allow us to facilitate seamless transactions and ensure the timely delivery of high-quality raw materials to our valued clients. 
      
      Cielx takes pride in its commitment to traceability and transparency across the supply chain. Leveraging advanced automation technologies, we meticulously monitor and track the journey of raw materials, guaranteeing adherence to stringent quality and compliance standards.`,
    description2:
      'As an approved supplier for recycled HDPE and LDPE materials for renowned brands, as well as being accredited for supplying recycled, virgin, and FSC-approved paper, Cielx has earned a reputation for excellence and reliability in the industry. Building on our success, we have expanded our offerings to include virgin materials, further enhancing our ability to cater to the evolving needs of our clients.',
    btnText: 'Learn More',
    btnLink: '/about-us',
  },
]
export const aboutUsPageAboutSection2 = [
  {
    title: 'IN QUEST OF SUBLIME MASTERY',
    image: require('../../images/background/aboutus2.png'),
    description1:
      'We are dedicated to maintaining an unwavering commitment to traceability and transparency throughout the supply chain, leveraging state-of-the-art automation technologies to provide seamless tracking and monitoring. Our goal is to guarantee adherence to the highest standards of quality and compliance, fostering trust and reliability in every partnership. We aspire to set new benchmarks in supply chain transparency and efficiency, creating a model for the industry that prioritizes sustainability and ethical practices.',
    description2:
      'By continuously advancing our technologies and expanding our global reach, we envision a future where every garment produced with our materials stands as a testament to excellence and responsibility. We believe in the power of innovation to drive progress and are committed to integrating cutting-edge technologies that enhance traceability and compliance. Our values of integrity, accountability, and customer-centricity guide our actions and decisions, ensuring that we consistently meet and exceed the expectations of our clients. We are devoted to creating a positive impact on the RMG sector, our community, and the environment, championing practices that promote ethical sourcing and sustainable growth.',
    btnText: 'Learn More',
    btnLink: '/about-us',
  },
]
export const certificationData = [
  {
    title: 'Certifications',
    image: require('../../images/background/chunkimagecertificate.png'),
    description1:
      ' Certifications are crucial in promoting sustainability and ethical practices within industries. By requiring third-party audits of materials, factories, and traders, they ensure greater transparency and accountability, reducing the risk of misleading claims, or greenwashing.',
    description2:
      ' This independent verification helps consumers make informed choices, fostering trust in the authenticity of eco-friendly and fair trade products. Furthermore, certifications play a key role in raising awareness about sustainable practices, encouraging broader adoption of responsible methods throughout the industry. Through these measures, certifications drive positive change and support a more ethical marketplace.',
    btnText: 'View More',
    btnLink: '/certificate-details',
  },
]
export const aboutSection2 = [
  {
    title: 'A Future With Us',
    image: require('../../images/background/homeFuture.png'),
    description1:
      'Cielx stands as a beacon of unwavering dedication to its cherished clientele, weaving a future where customer care and punctuality are paramount. Our commitment to delivering excellence is matched only by our resolute stance on environmental stewardship, ensuring that every product we offer reflects our ethos of integrity and innovation.',
    description2:
      'Embrace the opportunity to partner with us, and experience a future where your needs are meticulously met and timely delivered, reflecting the very essence of our uncompromising standards. With Cielx, you choose a path to a prosperous and sustainable future, where your satisfaction is our highest priority.',
    btnText: 'See More',
    btnLink: '/future',
  },
]
export const officeMapContent = [
  {
    title: 'Office Contacts',
    image: require('../../images/background/map.png'),
    description1:
      'A journey toward long lasting relationship is our core vision. The vision is simple-utmost customer satisfaction. The goal is to meet customer expectations therefore and provide the finest products while offering the best value for quality, cost and flexibility.',
    btnText: 'See More',
    btnLink: '/about-us',
  },
]
export const productSliderContent = [
  {
    title: 'PRODUCT PICTURES SLIDE HERE',
    image: require('../../images/product/item3.jpg'),
    description1:
      'Cielx is a leading garments accessories manufacturer and a top-tier  brand name in the global apparel industry – trusted for its superlative quality assurance. We embarked on an ever-growing journey of creativity and longevity in 2015, and our ship of merit is now marching forward with about a thousand employees onboard.',
    description2:
      'We are now  operating from as many as five regional offices – UK, Denmark, Germany, Spain, and China, along with a huge lush green manufacturing facility in Bangladesh.',
    btnText: 'Learn More',
    btnLink: '/about-us',
  },
  {
    title: 'PRODUCT PICTURES SLIDE HERE',
    image: require('../../images/product/item1.jpg'),
    description1:
      'Cielx is a leading garments accessories manufacturer and a top-tier  brand name in the global apparel industry – trusted for its superlative quality assurance. We embarked on an ever-growing journey of creativity and longevity in 2015, and our ship of merit is now marching forward with about a thousand employees onboard.',
    description2:
      'We are now  operating from as many as five regional offices – UK, Denmark, Germany, Spain, and China, along with a huge lush green manufacturing facility in Bangladesh.',
    btnText: 'Learn More',
    btnLink: '/about-us',
  },
  {
    title: 'PRODUCT PICTURES SLIDE HERE',
    image: require('../../images/product/item2.jpg'),
    description1:
      'Cielx is a leading garments accessories manufacturer and a top-tier  brand name in the global apparel industry – trusted for its superlative quality assurance. We embarked on an ever-growing journey of creativity and longevity in 2015, and our ship of merit is now marching forward with about a thousand employees onboard.',
    description2:
      'We are now  operating from as many as five regional offices – UK, Denmark, Germany, Spain, and China, along with a huge lush green manufacturing facility in Bangladesh.',
    btnText: 'Learn More',
    btnLink: '/about-us',
  },
  {
    title: 'PRODUCT PICTURES SLIDE HERE',
    image: require('../../images/product/item3.jpg'),
    description1:
      'Cielx is a leading garments accessories manufacturer and a top-tier  brand name in the global apparel industry – trusted for its superlative quality assurance. We embarked on an ever-growing journey of creativity and longevity in 2015, and our ship of merit is now marching forward with about a thousand employees onboard.',
    description2:
      'We are now  operating from as many as five regional offices – UK, Denmark, Germany, Spain, and China, along with a huge lush green manufacturing facility in Bangladesh.',
    btnText: 'Learn More',
    btnLink: '/about-us',
  },
  {
    title: 'PRODUCT PICTURES SLIDE HERE',
    image: require('../../images/product/item1.jpg'),
    description1:
      'Cielx is a leading garments accessories manufacturer and a top-tier  brand name in the global apparel industry – trusted for its superlative quality assurance. We embarked on an ever-growing journey of creativity and longevity in 2015, and our ship of merit is now marching forward with about a thousand employees onboard.',
    description2:
      'We are now  operating from as many as five regional offices – UK, Denmark, Germany, Spain, and China, along with a huge lush green manufacturing facility in Bangladesh.',
    btnText: 'Learn More',
    btnLink: '/about-us',
  },
  {
    title: 'PRODUCT PICTURES SLIDE HERE',
    image: require('../../images/product/item2.jpg'),
    description1:
      'Cielx is a leading garments accessories manufacturer and a top-tier  brand name in the global apparel industry – trusted for its superlative quality assurance. We embarked on an ever-growing journey of creativity and longevity in 2015, and our ship of merit is now marching forward with about a thousand employees onboard.',
    description2:
      'We are now  operating from as many as five regional offices – UK, Denmark, Germany, Spain, and China, along with a huge lush green manufacturing facility in Bangladesh.',
    btnText: 'Learn More',
    btnLink: '/about-us',
  },
]
export const testimonialContent = [
  {
    title:
      'Untitled helped us at every step of the process setting up our new Product.',
    image: require('../../images/background/userImage.png'),
    userImage: require('../../images/background/Avatar.png'),
    userName: 'John Doe',
    userDesignation: 'CEO, XYZ',
    description1:
      'A journey toward long lasting relationship is our core vision. The vision is simple-utmost customer satisfaction. The goal is to meet customer expectations therefore and provide the finest products while offering the best value for quality, cost and flexibility.',
    description2:
      'From ideation and design to mass production, J’adoube makes no compromise with its core value, sustainability. We emphasize maintaining two-way relations – with the consumers and with nature.',
    btnText: 'See More',
    btnLink: '/about-us',
  },
  {
    title:
      'Untitled helped us at every step of the process setting up our new Product.',
    image: require('../../images/background/userImage.png'),
    userImage: require('../../images/background/Avatar.png'),
    userName: 'John Doe',
    userDesignation: 'CEO, XYZ',
    description1:
      'A journey toward long lasting relationship is our core vision. The vision is simple-utmost customer satisfaction. The goal is to meet customer expectations therefore and provide the finest products while offering the best value for quality, cost and flexibility.',
    description2:
      'From ideation and design to mass production, J’adoube makes no compromise with its core value, sustainability. We emphasize maintaining two-way relations – with the consumers and with nature.',
    btnText: 'See More',
    btnLink: '/about-us',
  },
  {
    title:
      'Untitled helped us at every step of the process setting up our new Product.',
    image: require('../../images/background/userImage.png'),
    userImage: require('../../images/background/Avatar.png'),
    userName: 'John Doe',
    userDesignation: 'CEO, XYZ',
    description1:
      'A journey toward long lasting relationship is our core vision. The vision is simple-utmost customer satisfaction. The goal is to meet customer expectations therefore and provide the finest products while offering the best value for quality, cost and flexibility.',
    description2:
      'From ideation and design to mass production, J’adoube makes no compromise with its core value, sustainability. We emphasize maintaining two-way relations – with the consumers and with nature.',
    btnText: 'See More',
    btnLink: '/about-us',
  },
]

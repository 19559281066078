import React, { useEffect, useState } from "react";
import HomeSlider2 from "../../element/homeSlider2";
import { homeSliderContent8 } from "../../element/SliderContent";
import Header from "../../layout/header10";
import { getBanners, getProductById, getSustainability, IMAGE_PREFIX } from "../../server";
import CommonBannerSlider from "../../element/CommonBannerSlider";
import FooterHome from "../../layout/footerHome";
import ScrollToTop from "../../element/scrollToTop";
import { useParams } from "react-router-dom";

const ProductDetails = () => {
    const { id } = useParams();
    const [banner, setBanner] = useState(null);
    const [product, setProduct] = useState(null);
    useEffect(() => {
        getBanners({ type: "PRODUCT_AND_SERVICES" })
            .then((res) => {
                console.log("resAboutUs", res);
                if (res) {
                    setBanner(res?.data[0]);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    useEffect(() => {
        getProductById(id)
            .then((res) => {
                console.log("res", res);
                if (res) {
                    setProduct(res?.data);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    return (
        <div>
            <Header />
            <div className="page-content home-slider-10" style={{ backgroundColor: "#E1E1E1", paddingBottom: 0 }}>
                <CommonBannerSlider
                    data={banner}
                    contentWrapperCls="sliderStyle10"
                    subTitleCls="text-primary"
                    btnCls="text-primary"
                    btnText="Continue The Journey"
                />
                <div className="content-block">
                    <div className={`about-section-wrapper about-section-solid`} style={{ paddingBottom: 0 }}>
                        <div className="cielx-new-container"
                        // style={{ marginTop: 40 }}
                        >
                            <div className="growth-div-one">

                                <div className="div">
                                    <div className="text-wrapper">{product?.title}</div>
                                    <p className="cielx-stands-as-a">
                                        {product?.description}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`about-section-wrapper about-section-solid`}>
                        <div className="cielx-new-container">
                            {product ?


                                <div className="cielx-product-grid">
                                    {product?.docs?.map((image, index) => (
                                        <div key={index} className="cielx-product-item">
                                            <img
                                                className="cielx-product-image"
                                                alt={image.urlType}
                                                src={`${IMAGE_PREFIX}/${image?.url}`}
                                            // Assuming 'image' is the field name in API response
                                            />

                                        </div>
                                    ))}
                                </div>

                                // <div className="certificate-grid">
                                //     {certificates?.map((certificate, index) => (
                                //         <div key={index} className="certificate-card">
                                //             <div className="certificate-image-container">
                                //                 <img
                                //                     src={`${IMAGE_PREFIX}/${certificate?.image?.url}`}
                                //                     alt={certificate.title}
                                //                     className="certificate-image"
                                //                 />
                                //             </div>

                                //             <div
                                //                 dangerouslySetInnerHTML={{
                                //                     __html: certificate.body,
                                //                 }}
                                //                 className="certificate-description"
                                //             ></div>
                                //         </div>
                                //     ))}
                                // </div> 
                                : null}  </div></div>



                </div>

            </div>
            <FooterHome />
            <ScrollToTop className='style3' />
        </div>
    );
};

export default ProductDetails;

import React, { Component } from "react";
//import { Link } from 'react-router-dom';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

//
const content = [
  {
    image: require("../../images/background/logo1.png"),
  },
  {
    image: require("../../images/background/logo2.png"),
  },
  {
    image: require("../../images/background/logo3.png"),
  },
  {
    image: require("../../images/background/logo9.png"),
  },
  {
    image: require("../../images/background/logo6.png"),
  },
  {
    image: require("../../images/background/logo7.png"),
  },
  {
    image: require("../../images/background/logo8.png"),
  },
  {
    image: require("../../images/background/logo3.png"),
  },
  {
    image: require("../../images/background/logo1.png"),
  },
  {
    image: require("../../images/background/logo2.png"),
  },
];

class LogoSection extends Component {
  render() {
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 3,
      autoplay: true,
      autoplaySpeed: 5000,
      arrows: false,
      responsive: [
        {
          breakpoint: 500,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    return (
      <>
        <div className="logo-wrapper-slider" style={{ backgroundColor: "#fff" }}>
          <div className="cielx-new-container" style={{ marginBottom: 35 }}>
            <div className="cielx-testimonial-heading" style={{ marginBottom: 35 }}>Our Partners</div>
            <Slider {...settings}>
              {content.map((item, id) => (
                <div key={id} className="col">
                  <img src={item.image} alt={`Logo ${id + 1}`} className="w-100" />
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </>
    );
  }
}
export default LogoSection;

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  aboutSection2,
  aboutSectionContentFirst,
  aboutSectionReverseContent,
  certificationData,
  officeMapContent,
  productSliderContent,
} from "../element/SliderContent";
import AboutSection from "../element/aboutSection";
import AboutSectionReverse from "../element/aboutSectionReverse";
import FlipCard from "../element/flipCard";
import HomeCounter from "../element/homeCounter";
import ProductSlider2 from "../element/productSlider2";
import ScrollToTop from "../element/scrollToTop";
import Header from "../layout/header10";

import BannerSlider from "../element/BannerSlider/BannerSlider";
import MapSection from "../element/MapSection";
import CertificateSection from "../element/certificateSection";
import LogoSection from "../element/logoSection";
import TestimonialHome from "../element/testimonialHome";
import FooterHome from "../layout/footerHome";
import { getBanners, getSectionContent, IMAGE_PREFIX } from "../server";
// import '../../css/skin/skin-8.min.css';

const images = [
  {
    id: 1,
    name: "healthy",
    title: "Solid Wastes",
    imgUrl: require("../../images/gallery/leather/pic1.jpg"),
  },
  {
    id: 2,
    name: "food",
    title: "Leather Products",
    imgUrl: require("../../images/gallery/leather/pic2.jpg"),
  },
  {
    id: 3,
    name: "organic",
    title: "Leather processing",
    imgUrl: require("../../images/gallery/leather/pic3.jpg"),
  },
  {
    id: 4,
    name: "organic",
    title: "Industrial Apartment",
    imgUrl: require("../../images/gallery/leather/pic4.jpg"),
  },
];
const bg = require("../../images/background/counterBg.png");

const Index10 = () => {
  const [banner, setBanner] = useState(null);
  const [homeAboutUsData, setHomeAboutUsData] = useState(null);
  const [homeFutureData, setHomeFutureData] = useState(null);
  const [integrityData, setIntegrityData] = useState(null);
  const [counterScaleData, setCounterScaleData] = useState(null);
  const [homeServiceData, setHomeServiceData] = useState(null);
  const [homeGrowthData, setHomeGrowthData] = useState(null);
  const [homeOurProductData, setHomeOurProductData] = useState(null);
  const [homeCertificateData, setHomeCertificateData] = useState(null);
  const [partnerData, setPartnerData] = useState(null);
  const [mapContactData, setMapContactData] = useState(null);
  const fetchSectionData = () => {
    getSectionContent({ type: "HOMEABOUTUS" })
      .then((res) => {
        console.log("section :", res);
        if (res) {
          setHomeAboutUsData(res?.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchFutureData = () => {
    getSectionContent({ type: "HOMEFUTURE" })
      .then((res) => {
        console.log("section :", res);
        if (res) {
          setHomeFutureData(res?.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const fetchIntegrityData = () => {
    getSectionContent({ type: "INTEGRITY,DEDICATION,EXCELLENCE" })
      .then((res) => {
        console.log("section :", res);
        if (res) {
          setIntegrityData(res?.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const fetchCounterScaleData = () => {
    getSectionContent({ type: "COUNTERSCALE" })
      .then((res) => {
        console.log("section :", res);
        if (res) {
          setCounterScaleData(res?.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const fetchHomeServiceData = () => {
    getSectionContent({ type: "HOMESERVICES" })
      .then((res) => {
        console.log("section :", res);
        if (res) {
          setHomeServiceData(res?.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const fetchHomeGrowthData = () => {
    getSectionContent({ type: "HOMEGROWTH" })
      .then((res) => {
        console.log("section :", res);
        if (res) {
          setHomeGrowthData(res?.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const fetchCertificateData = () => {
    getSectionContent({ type: "HOMECERTIFICATIONS" })
      .then((res) => {
        console.log("section :", res);
        if (res) {
          setHomeCertificateData(res?.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const fetchHomeOurProductData = () => {
    getSectionContent({ type: "HOMEOURPRODUCTS" })
      .then((res) => {
        console.log("section :", res);
        if (res) {
          setHomeOurProductData(res?.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const fetchPartnerData = () => {
    getSectionContent({ type: "HOMEOURPARTNERS" })
      .then((res) => {
        console.log("section :", res);
        if (res) {
          setPartnerData(res?.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchMapContactData = () => {
    getSectionContent({ type: "HOMEMAP" })
      .then((res) => {
        console.log("section :", res);
        if (res) {
          setMapContactData(res?.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getBanners({ type: "HOME" })
      .then((res) => {
        console.log("resAboutUs", res);
        if (res) {
          setBanner(res?.data[0]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    fetchSectionData();
    fetchFutureData();
    fetchIntegrityData();
    fetchCounterScaleData();
    fetchHomeServiceData();
    fetchHomeGrowthData();
    fetchHomeOurProductData();
    fetchCertificateData();
    fetchPartnerData();
    fetchMapContactData();
  }, []);

  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const onScroll = () => {
      const element = document.querySelector(".cielx-second-container");
      if (element) {
        const rect = element.getBoundingClientRect();
        if (rect.top <= window.innerHeight && rect.bottom >= 0) {
          setIsVisible(true); // Start animation when section is in view
        }
      }
    };
    window.addEventListener("scroll", onScroll);

    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, []);

  const containerStyle = {
    opacity: isVisible ? 1 : 0,
    transform: isVisible ? "translateY(0)" : "translateY(-100px)",
    transition: "all 2.5s ease",
  };
  return (
    <>
      <Header />

      <div className="page-content bg-white home-slider-10">
        <BannerSlider slides={banner} />
        <div className="content-block">
          <div
            className="home-second-section-wrapper"
            style={{
              backgroundImage: integrityData?.backgroundImage
                ? `url(${IMAGE_PREFIX}/${integrityData?.backgroundImage?.url})`
                : "none",
            }}
          >
            <div className="cielx-second-container" style={containerStyle}>
              <div className="home-second-single">
                <div className="second-card-wrapper">
                  <img
                    src={`${IMAGE_PREFIX}/${integrityData?.boxElements[0]?.icon?.url}`}
                  />
                  <h4>{integrityData?.boxElements[0]?.title}</h4>
                  <p>{integrityData?.boxElements[0]?.description}</p>
                </div>

                <div className="second-card-wrapper">
                  <img
                    src={`${IMAGE_PREFIX}/${integrityData?.boxElements[1]?.icon?.url}`}
                  />
                  <h4>{integrityData?.boxElements[1]?.title}</h4>
                  <p>{integrityData?.boxElements[1]?.description}</p>
                </div>

                <div className="second-card-wrapper">
                  <img
                    src={`${IMAGE_PREFIX}/${integrityData?.boxElements[2]?.icon?.url}`}
                  />
                  <h4>{integrityData?.boxElements[2]?.title}</h4>
                  <p>{integrityData?.boxElements[2]?.description}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="section-full">
            <AboutSection
              data={aboutSectionContentFirst}
              sectionData={homeAboutUsData}
              className="about-section-solid"
            />
          </div>
          <div className="counter-wrapper">
            <HomeCounter backgroundImage={bg} sectionData={counterScaleData} />
          </div>
          <div className="flip-card-section-wrapper">
            <FlipCard sectionData={homeServiceData} />
          </div>
          <div className="section-full">
            <AboutSectionReverse
              data={aboutSectionReverseContent}
              className="about-section-solid"
              sectionData={homeGrowthData}
            />
          </div>
          <div
            className="product-small-section"
            style={{
              backgroundImage: homeOurProductData?.backgroundImage
                ? `url(${IMAGE_PREFIX}/${homeOurProductData?.backgroundImage?.url})`
                : "none",
            }}
          >
            <div className="cielx-new-container">
              <div className="row product-small-row">
                <div
                  className="col-lg-6"
                  style={{ flexDirection: "column", marginTop: 40 }}
                >
                  <h3 style={{ fontFamily: "Montserrat" }}>
                    {homeOurProductData?.mainTitle}
                  </h3>
                  <p style={{ fontFamily: "Crimson Text" }}>
                    {homeOurProductData?.description1}{" "}
                  </p>
                </div>
                <div className="col-lg-6 pss-btn">
                  <Link to="/all-products" className="custom-primary-btn mt-30">
                    See More
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <ProductSlider2
            data={productSliderContent}
            contentWrapperCls="product-slider-wrapper"
            subTitleCls="text-primary"
            btnCls="text-primary"
            btnText="Continue The Journey"
            sectionData={homeOurProductData}
          />
          <div className="section-full">
            <CertificateSection
              data={certificationData}
              className="about-section-linear"
              sectionData={homeCertificateData}
            />
          </div>
          <div className="section-full logo-section-main-wrapper">
            <LogoSection sectionData={partnerData} />
          </div>
          <div className="section-full">
            <AboutSection
              data={aboutSection2}
              sectionData={homeFutureData}
              className="about-section-solid"
            />
          </div>
          {/* <div className='section-full testimonial-section-wrapper'>
            <TestimonialHome />
          </div> */}
          <TestimonialHome />
          <div className="section-full">
            <MapSection
              data={officeMapContent}
              className="about-section-solid"
              sectionData={mapContactData}
            />
          </div>
        </div>
      </div>
      <FooterHome />
      <ScrollToTop className="style3" />
    </>
  );
};

export default Index10;

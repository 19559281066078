import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { IMAGE_PREFIX } from "../server";

const content = [
  { image: require("../../images/background/logo1.png") },
  { image: require("../../images/background/logo2.png") },
  { image: require("../../images/background/logo3.png") },
  { image: require("../../images/background/logo9.png") },
  { image: require("../../images/background/logo6.png") },
  { image: require("../../images/background/logo7.png") },
  { image: require("../../images/background/logo8.png") },
  { image: require("../../images/background/logo3.png") },
  { image: require("../../images/background/logo1.png") },
  { image: require("../../images/background/logo2.png") },
];

const LogoSection = ({ sectionData }) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    responsive: [
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="logo-wrapper-slider" style={{ backgroundColor: "#fff" }}>
      <div className="cielx-new-container" style={{ marginBottom: 35 }}>
        <div className="cielx-testimonial-heading" style={{ marginBottom: 35 }}>
          {sectionData?.mainTitle}
        </div>
        <Slider {...settings}>
          {sectionData?.images?.map((item, id) => (
            <div key={id} className="col">
              <img src={`${IMAGE_PREFIX}/${item.url}`} alt={`Logo ${id + 1}`} className="w-100" />
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default LogoSection;

import React, { useEffect, useState } from "react";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps";
import Header from "../layout/header10";
import CommonBannerSlider from "../element/CommonBannerSlider";
import FooterHome from "../layout/footerHome";
import { getBanners } from "../server";

function ContactUs() {
  const [banner, setBanner] = useState(null);
  const [viewportWidth, setViewportWidth] = useState(window.innerWidth);

  // Update viewport width state on window resize
  const updateWindowDimensions = () => {
    setViewportWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", updateWindowDimensions);
    return () => {
      window.removeEventListener("resize", updateWindowDimensions);
    };
  }, []);

  useEffect(() => {
    getBanners({ type: "CONTACT" })
      .then((res) => {
        if (res) {
          setBanner(res?.data[0]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // Define breakpoints
  const isMobile = viewportWidth <= 480;
  const isTablet = viewportWidth > 480 && viewportWidth <= 1024;

  const MyMapComponent = withScriptjs(
    withGoogleMap((props) => (
      <GoogleMap
        google={props.google}
        defaultZoom={18}
        defaultCenter={{
          lat: 23.838333,
          lng: 90.376111,
        }}
      >
        <Marker key={1} position={{ lat: 23.838333, lng: 90.376111 }} />
      </GoogleMap>
    ))
  );

  return (
    <>
      <Header />
      <div className="home-slider-10">
        <CommonBannerSlider
          data={banner}
          contentWrapperCls="sliderStyle10"
          subTitleCls="text-primary"
          btnCls="text-primary"
          btnText="Continue The Journey"
        />

        <div className="section-full bg-grey" style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}>
          <div
            className="cielx-new-container"
          >
            <div className="dlab-bnr-inr-entry page-header-text contact-page-header">
              <h3>Office Contacts</h3>
              <p>
                Our team of experts creates and designs products that follow
                lofty standards, making them a go-to name in international
                markets. Our products are ingrained with three qualities –
                creativity, durability, and sustainability, all blended together
                to generate a soothing experience. We strictly comply with
                environmental safety regulations through our 100% eco-friendly
                products and materials.
              </p>
            </div>
          </div>
        </div>

        <div className="bg-grey contact-map-wrapper">
          <div
            className="container contact-container"
            style={{
              padding: isMobile ? "20px" : isTablet ? "40px" : "60px",
            }}
          >
            <div className="row contact-row">
              <div className="col-lg-4 col-md-6 col-sm-12 m-b30 single-contact-wrapper">
                <div className="single-contact">
                  <div className="icon-wrapper">
                    <i className="ti-email"></i>
                  </div>
                  <h3>Email</h3>
                  <p>fuad@cielx.org</p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 m-b30 single-contact-wrapper">
                <div className="single-contact">
                  <div className="icon-wrapper">
                    <i className="ti-location-pin"></i>
                  </div>
                  <h3>Address</h3>
                  <p>
                    House 996 (1st floor), Road: 9/A, Avenue: 11, Mirpur DOHS,
                    Pallabi, Dhaka- 1216, Bangladesh.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 m-b30 single-contact-wrapper">
                <div className="single-contact">
                  <div className="icon-wrapper">
                    <i className="ti-email"></i>
                  </div>
                  <h3>Whatsapp</h3>
                  <p>+880 1717-855488</p>
                </div>
              </div>
            </div>
          </div>

          <div
            className="custom-contact-container"
            style={{
              padding: isMobile ? "20px" : isTablet ? "40px" : "60px",
            }}
          >
            <MyMapComponent
              isMarkerShown
              googleMapURL={`https://maps.googleapis.com/maps/api/js?key=AIzaSyB78hfZtM0bfvCzv3BqPoqI5wT6JkqJDtw&v=3.exp&libraries=geometry,drawing,places`}
              loadingElement={<div style={{ height: `100%` }} />}
              containerElement={<div style={{ height: `400px` }} />}
              mapElement={<div style={{ height: `100%` }} />}
            />
          </div>
        </div>
      </div>
      <FooterHome />
    </>
  );
}

export default ContactUs;

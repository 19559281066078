import React, { useEffect, useState } from "react";
import HomeSlider2 from "../../element/homeSlider2";
import { homeSliderContent8 } from "../../element/SliderContent";
import Header from "../../layout/header10";
import { getBanners, getSectionContent, getSustainability, IMAGE_PREFIX } from "../../server";
import CommonBannerSlider from "../../element/CommonBannerSlider";
import FooterHome from "../../layout/footerHome";
import ScrollToTop from "../../element/scrollToTop";

const CertificateDetails = () => {
  const [banner, setBanner] = useState(null);
  const [pageData1, setPageData1] = useState(null)
  const [pageData2, setPageData2] = useState(null)
  const [pageData3, setPageData3] = useState(null)


  const fetchPageData1 = () => {
    getSectionContent({ type: "WHAT_CERTIFICATIONS_SIGNIFY" })
      .then((res) => {
        console.log("section :", res);
        if (res) {
          setPageData1(res?.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const fetchPageData2 = () => {
    getSectionContent({ type: "RIGOROUS_PROCESS" })
      .then((res) => {
        console.log("section :", res);
        if (res) {
          setPageData2(res?.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const fetchPageData3 = () => {
    getSectionContent({ type: "MAKING_A_BRAND_NAME" })
      .then((res) => {
        console.log("section :", res);
        if (res) {
          setPageData3(res?.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }



  const [certificates, setCertificates] = useState(null);
  useEffect(() => {
    getBanners({ type: "CERTIFICATES" })
      .then((res) => {
        console.log("resAboutUs", res);
        if (res) {
          setBanner(res?.data[0]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    fetchPageData1()
    fetchPageData2()
    fetchPageData3()
  }, []);

  useEffect(() => {
    getSustainability({ type: "environmental" })
      .then((res) => {
        console.log("res", res);
        if (res) {
          setCertificates(res?.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div>
      <Header />
      <div className="page-content home-slider-10" style={{ backgroundColor: "#E1E1E1", paddingBottom: 0 }}>
        <CommonBannerSlider
          data={banner}
          contentWrapperCls="sliderStyle10"
          subTitleCls="text-primary"
          btnCls="text-primary"
          btnText="Continue The Journey"
        />
        <div className="details-wrapper">
          <div className="certificate-intro"
            style={{ backgroundImage: pageData1?.backgroundImage ? `url(${IMAGE_PREFIX}/${pageData1?.backgroundImage?.url})` : "none" }}
          >
            <h2>{pageData1?.mainTitle}</h2>
            <div>
              <p style={{ fontFamily: "Crimson Text", fontSize: 22 }}>
                {pageData1?.description1}
              </p>
            </div>
            <div>
              <p style={{ fontFamily: "Crimson Text", fontSize: 22 }}>
                {pageData1?.description2}
              </p>
            </div>
            <h2>{pageData2?.mainTitle}</h2>
            <div>
              <p style={{ fontFamily: "Crimson Text", fontSize: 22 }}>
                {pageData2?.description1}
              </p>
            </div>
            <div>
              <p style={{ fontFamily: "Crimson Text", fontSize: 22 }}>
                {pageData2?.description2}
              </p>
            </div>
          </div>
          {certificates ? <div className="certificate-grid">
            {certificates?.map((certificate, index) => (
              <div key={index} className="certificate-card">
                <div className="certificate-image-container">
                  <img
                    src={`${IMAGE_PREFIX}/${certificate?.image?.url}`}
                    alt={certificate.title}
                    className="certificate-image"
                  />
                </div>
                <p className="certificate-description"><div
                  dangerouslySetInnerHTML={{
                    __html: certificate.body,
                  }}

                ></div></p>

              </div>
            ))}
          </div> : null}


        </div>
        <div className="certificate-footer">
          <img
            src={`${IMAGE_PREFIX}/${pageData3?.backgroundImage?.url}`}

            alt="Logo"
            className="certificate-footer-logo"
          />
          <h2 className="certificate-footer-header">{pageData3?.mainTitle}</h2>
          <p className="certificate-footer-paragraph">
            {pageData3?.description1}
          </p>
        </div>
      </div>
      <FooterHome />
      <ScrollToTop className='style3' />
    </div>
  );
};

export default CertificateDetails;

import React, { useEffect, useState } from "react";
import HomeSlider2 from "../../element/homeSlider2";
import { homeSliderContent8 } from "../../element/SliderContent";
import Header from "../../layout/header10";
import { getBanners, getCielxProducts, getSectionContent, IMAGE_PREFIX } from "../../server";
import CommonBannerSlider from "../../element/CommonBannerSlider";
import FooterHome from "../../layout/footerHome";
import ScrollToTop from "../../element/scrollToTop";
import { Link } from "react-router-dom";
import "./product.css"

const ProductsPage = () => {
    const [banner, setBanner] = useState(null);
    const [products, setProducts] = useState(null);
    const [activeTab, setActiveTab] = useState('Raw Materials');
    const [loading, setLoading] = useState(true); // State to track if video is loading

    const [pageData, setPageData] = useState(null)
    const [automationData, setAutomationData] = useState(null)

    const fetchPageData = () => {
        getSectionContent({ type: "BUILT_FOR_TOMORROW" })
            .then((res) => {
                console.log("section :", res);
                if (res) {
                    setPageData(res?.data);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }
    const fetchAutomationData = () => {
        getSectionContent({ type: "AUTOMATION" })
            .then((res) => {
                console.log("section :", res);
                if (res) {
                    setAutomationData(res?.data);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    useEffect(() => {
        fetchAutomationData()
        fetchPageData()
    }, []);
    const handleVideoLoad = () => {
        setLoading(false); // Video has loaded, hide the loader
    };

    useEffect(() => {
        getBanners({ type: "PRODUCT_AND_SERVICES" })
            .then((res) => {
                if (res) {
                    setBanner(res?.data[0]);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    useEffect(() => {
        if (activeTab === 'Raw Materials') {
            getCielxProducts({ type: "photo gallery" })
                .then((res) => {
                    if (res) {
                        setProducts(res?.data);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
        setLoading(true);
    }, [activeTab]);

    const renderTabContent = () => {
        if (activeTab === 'Raw Materials') {
            return (
                // <div className="cielx-product-grid">
                //     {products?.map((product, index) => (
                //         <Link key={index} to={'/product/' + product?.id} className="cielx-product-item">
                //             <img
                //                 className="cielx-product-image"
                //                 alt={product.title}
                //                 src={`${IMAGE_PREFIX}/${product?.docs[0]?.url}`}
                //             />
                //             <div className="cielx-product-title">{product.title}</div>
                //         </Link>
                //     ))}
                // </div>
                <div className="cielx-product-grid">
                    {products?.map((product, index) => (
                        <div key={index} className="cielx-product-item product-flip-card">
                            <div className="product-flip-card-inner">
                                <div className="product-flip-card-front">
                                    <img
                                        className="cielx-product-image"
                                        alt={product.title}
                                        src={`${IMAGE_PREFIX}/${product?.docs[0]?.url}`}
                                    />

                                </div>
                                <div className="product-flip-card-back">
                                    <div className="product-details">
                                        <p>{product.description.slice(0, 150)}...</p> {/* Show a small part of the description */}

                                    </div>
                                    <Link to={'/product/' + product.id} className="more-button" style={{ fontFamily: "Montserrat" }}>
                                        More
                                    </Link>
                                </div>

                            </div>
                            <div className="cielx-product-title">{product.title}</div>
                        </div>
                    ))}
                </div>
            );
        }
        else if (activeTab === 'Traceability') {
            return (
                <div className="video-wrapper">
                    {loading && (
                        <div className="loader-container">
                            <div className="loader"></div>
                            <p>Loading video...</p>
                        </div>
                    )}

                    <div className="video-container" style={{ display: loading ? 'none' : 'block' }}>
                        <iframe
                            width="100%"
                            height="568px"
                            src="https://drive.google.com/file/d/1yKdJBcxlsZ6_W7RBNjbpnRufeJ9c07kX/preview"
                            title="Drive video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            referrerPolicy="strict-origin-when-cross-origin"
                            allowFullScreen
                            onLoad={handleVideoLoad} // Detect when the video is loaded
                        ></iframe>
                    </div>
                </div>
            );
        }
        else if (activeTab === 'Automation') {
            return (
                // <div className="cielx-product-grid">

                <div className="cielx-product-item automation-image-card" >
                    {/* <div className="product-flip-card-inner"> */}
                    <div className="" >
                        <img
                            className="cielx-automation-image"
                            alt="automation pic"

                            src={`${IMAGE_PREFIX}/${automationData?.backgroundImage?.url}`}

                        />

                    </div>


                    {/* </div> */}
                </div>

                // {/* </div> */}
            );
        } else {
            return (
                <div className="coming-soon-message">
                    <h3>Feature Coming Soon</h3>
                </div>
            );
        }
    };

    return (
        <div>
            <Header />
            <div className="page-content home-slider-10" style={{ backgroundColor: "#E1E1E1", paddingBottom: 0 }}>
                <CommonBannerSlider
                    data={banner}
                    contentWrapperCls="sliderStyle10"
                    subTitleCls="text-primary"
                    btnCls="text-primary"
                    btnText="Continue The Journey"
                />
                <div className="content-block">
                    <div className={`about-section-wrapper about-section-solid`} style={{ paddingBottom: 0 }}>
                        <div className="cielx-new-container"
                        //  style={{ marginTop: 40 }}
                        >
                            <div className="growth-div-one">
                                <div className="div">
                                    <div className="text-wrapper">{pageData?.mainTitle}</div>
                                    <p className="cielx-stands-as-a">
                                        {pageData?.description1}
                                        <br />

                                        <br />
                                        {pageData?.description2}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={`about-section-wrapper about-section-solid`}>
                        <div className="cielx-new-container">
                            {/* Tab Navigation */}
                            <div className="tabs">
                                <button onClick={() => setActiveTab('Raw Materials')} className={activeTab === 'Raw Materials' ? 'active' : ''}>Raw Materials</button>
                                <button onClick={() => setActiveTab('Globalization')} className={activeTab === 'Globalization' ? 'active' : ''}>Globalization</button>
                                <button onClick={() => setActiveTab('Traceability')} className={activeTab === 'Traceability' ? 'active' : ''}>Traceability</button>
                                <button onClick={() => setActiveTab('Automation')} className={activeTab === 'Automation' ? 'active' : ''}>Automation</button>
                            </div>

                            {/* Tab Content */}
                            <div className="tab-content">
                                {renderTabContent()}
                            </div>
                        </div>
                    </div>


                </div>
            </div>
            <FooterHome />
            <ScrollToTop className='style3' />
        </div>
    );
};

export default ProductsPage;

import React from "react";
import { IMAGE_PREFIX } from "../server";

export const NatureSection = ({ sectionData }) => {
    return (
        <div className="nature-container">
            <div className="div">
                <div className="text-wrapper">{sectionData?.mainTitle}</div>
                <p className="nature-provides-us">
                    {sectionData?.description1}
                </p>
            </div>
            <div className="div-2">
                <div className="flip-card">
                    <div className="flip-card-inner">
                        <div className="flip-card-front">
                            <img className="serene" alt="Serene"
                                src={`${IMAGE_PREFIX}/${sectionData?.boxElements[0]?.icon?.url}`}
                            />
                            <div className="text-wrapper-2">{sectionData?.boxElements[0]?.title}</div>
                        </div>
                        <div className="flip-card-back">
                            <p className="p">
                                {sectionData?.boxElements[0]?.description}
                            </p>
                        </div>
                    </div>
                </div>
                <div className="flip-card">
                    <div className="flip-card-inner">
                        <div className="flip-card-front">
                            <img className="achievement-traced" alt="Achievement traced"
                                src={`${IMAGE_PREFIX}/${sectionData?.boxElements[1]?.icon?.url}`} />
                            <div className="text-wrapper-2">{sectionData?.boxElements[1]?.title}</div>
                        </div>
                        <div className="flip-card-back">
                            <p className="p">
                                {sectionData?.boxElements[1]?.description}
                            </p>
                        </div>
                    </div>
                </div>
                <div className="flip-card">
                    <div className="flip-card-inner">
                        <div className="flip-card-front">
                            <img className="another-image" alt="Another Concept"
                                src={`${IMAGE_PREFIX}/${sectionData?.boxElements[2]?.icon?.url}`} />
                            <div className="text-wrapper-2">{sectionData?.boxElements[2]?.title}</div>
                        </div>
                        <div className="flip-card-back">
                            <p className="p">
                                {sectionData?.boxElements[2]?.description}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

import React, { Component, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Header from "../layout/header10";
import Footer from "../layout/footer";
import Testimonial1 from "../element/testimonial1";
import HomeSlider from "../element/homeSlider2";
import {
  aboutSectionContentFirst,
  aboutUsPageAboutSection,
  aboutUsPageAboutSection2,
  homeSliderContent8,
} from "../element/SliderContent";
import AboutSection from "../element/aboutSection";
import AboutSectionReverse from "../element/aboutSectionReverse";
import FooterHome from "../layout/footerHome";
import { getBanners, getSectionContent, IMAGE_PREFIX } from "../server";
import CommonBannerSlider from "../element/CommonBannerSlider";
var bnr3 = require("./../../images/banner/bnr3.jpg");
var bg1 = require("./../../images/background/bg-video.png");
var bg2 = require("./../../images/background/bg1.jpg");

const AboutUs = () => {
  const [banner, setBanner] = useState(null);
  const [aboutData1, setAboutData1] = useState(null);
  const [cardData, setCardData] = useState(null);
  const [aboutData2, setAboutData2] = useState(null);
  const fetchAboutData1 = () => {
    getSectionContent({ type: "WHO_WE_ARE" })
      .then((res) => {
        console.log("section :", res);
        if (res) {
          setAboutData1(res?.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const fetchCardData = () => {
    getSectionContent({ type: "MISSION,VISION,VALUES" })
      .then((res) => {
        console.log("section :", res);
        if (res) {
          setCardData(res?.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  const fetchAboutData2 = () => {
    getSectionContent({ type: "IN_QUEST_OF_SUBLIME_MASTERY" })
      .then((res) => {
        console.log("section :", res);
        if (res) {
          setAboutData2(res?.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  useEffect(() => {
    getBanners({ type: "ABOUT_US" })
      .then((res) => {
        console.log("resAboutUs", res);
        if (res) {
          setBanner(res?.data[0]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    fetchAboutData1()
    fetchCardData()
    fetchAboutData2()

  }, []);

  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const onScroll = () => {
      const element = document.querySelector('.vision-section-wrapper');
      if (element) {
        const rect = element.getBoundingClientRect();
        if (rect.top <= window.innerHeight && rect.bottom >= 0) {
          setIsVisible(true); // Start animation when section is in view
        }
      }
    };
    window.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  const containerStyle = {
    opacity: isVisible ? 1 : 0,
    transform: isVisible ? 'translateY(0)' : 'translateY(-100px)',
    transition: 'all 1.5s ease',
  };
  return (
    <>
      <Header />

      <div className="page-content bg-white home-slider-10">
        <CommonBannerSlider
          data={banner}
          contentWrapperCls="sliderStyle10"
          subTitleCls="text-primary"
          btnCls="text-primary"
          btnText="Continue The Journey"
        />
        <div className="content-block">
          <div className="section-full">
            <AboutSection
              data={aboutUsPageAboutSection}
              className="about-section-solid"
              btn="no"
              sectionData={aboutData1}
            />
          </div>
          <div className="section-full">
            <div className="vision-section-wrapper" style={containerStyle}>
              <div className="cielx-new-container" style={{ margin: "auto" }}>
                <div className="row">
                  <div className="col-md-6 col-lg-4 col-sm-12">
                    <div className="about-card" >
                      <img
                        src={`${IMAGE_PREFIX}/${cardData?.boxElements[0]?.icon?.url}`}

                        alt=""
                        style={{ width: 60, marginBottom: 24 }}
                      />
                      <h3>{cardData?.boxElements[0]?.title}</h3>
                      <p style={{ fontFamily: "Crimson Text" }}>
                        {cardData?.boxElements[0]?.description}
                      </p>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4 col-sm-12">
                    <div className="about-card" >
                      <img
                        src={`${IMAGE_PREFIX}/${cardData?.boxElements[1]?.icon?.url}`}
                        alt=""
                        style={{ width: 60, marginBottom: 24 }}
                      />
                      <h3>{cardData?.boxElements[1]?.title}</h3>
                      <p style={{ fontFamily: "Crimson Text" }}>
                        {cardData?.boxElements[1]?.description}
                      </p>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4 col-sm-12">
                    <div className="about-card">
                      <img
                        src={`${IMAGE_PREFIX}/${cardData?.boxElements[2]?.icon?.url}`}
                        alt=""
                        style={{ width: 60, marginBottom: 24 }}
                      />
                      <h3>{cardData?.boxElements[2]?.title}</h3>
                      <p style={{ fontFamily: "Crimson Text" }}>
                        {cardData?.boxElements[2]?.description}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="section-full">
            <AboutSectionReverse
              data={aboutUsPageAboutSection2}
              className="about-section-solid"
              btn="no"
              sectionData={aboutData2}
            />
          </div>
        </div>
      </div>

      <FooterHome />
    </>
  );
};

export default AboutUs;
import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { IMAGE_PREFIX, getSustainability } from '../server';

const CertificateSection = ({ className, data, sectionData }) => {
  const sliderContent = data;
  const [certificates, setCertificates] = useState([]);
  const certificatesRef = useRef(null); // Reference to the certificate container
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // Track if mobile view

  useEffect(() => {
    getSustainability({ type: 'environmental' })
      .then((res) => {
        if (res) {
          setCertificates(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    // Check if screen size is mobile and update state
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('zoom-show');
          } else {
            entry.target.classList.remove('zoom-show');
          }
        });
      },
      { threshold: 0.1 }
    );

    const elements = certificatesRef.current.querySelectorAll('.zoom-hidden');

    elements.forEach((el) => observer.observe(el));

    return () => {
      elements.forEach((el) => observer.unobserve(el));
    };
  }, [certificates]);

  return (
    <div className={`about-section-wrapper ${className}`}>
      <div className="cielx-new-container" ref={certificatesRef}>
        {sliderContent.map((item, index) => (
          <div
            className="zoom-hidden"
            key={index}
            style={{
              display: 'flex',
              flexDirection: isMobile ? 'column-reverse' : 'row', // Reverse on mobile
              alignItems: isMobile ? 'flex-start' : 'center',    // Adjust alignment on mobile
              marginBottom: '30px'
            }}
          >
            {/* Text section */}
            <div
              style={{
                flex: isMobile ? '0 0 100%' : '0 0 50%', // Full width on mobile
                textAlign: isMobile ? 'center' : 'left', // Center text on mobile
                padding: isMobile ? '10px 0' : '0'      // Adjust padding for mobile
              }}
            >
              <h4>{sectionData?.mainTitle}</h4>
              <p style={{ fontFamily: "Crimson Text", color: "white" }}>{sectionData?.description1}</p>
              <p style={{ fontFamily: "Crimson Text", color: "white" }}>{sectionData?.description2}</p>
              <Link to={item?.btnLink} className="custom-primary-btn mt-30">
                {item?.btnText}
              </Link>
            </div>

            {/* Image section */}
            <div
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: isMobile ? 'center' : 'flex-end', // Center on mobile
                flex: isMobile ? '0 0 100%' : '1', // Full width on mobile
              }}
            >
              {certificates.slice(0, 4).map((certificate, index) => (
                <div key={index} className='certificate-image-home-container'>
                  <img
                    src={`${IMAGE_PREFIX}/${certificate?.image?.url}`}
                    className="certificate-image-home zoom-hidden"
                    alt="certificate"

                  />
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CertificateSection;

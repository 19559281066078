import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";

const AboutSection = ({ data: sliderContent, btn, className }) => {
  const sectionRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            // Add 'show' class when the section is in view
            entry.target.classList.add("show");
          } else {
            // Optional: remove 'show' class when the section is out of view
            entry.target.classList.remove("show");
          }
        });
      },
      {
        threshold: 0.1, // Trigger when 10% of the section is visible
      }
    );

    const elements = sectionRef.current.querySelectorAll(".col-7, .col-4");

    elements.forEach((el) => observer.observe(el));

    // Clean up the observer when the component unmounts
    return () => {
      elements.forEach((el) => observer.unobserve(el));
    };
  }, []);

  return (
    <div className={`about-section-wrapper ${className}`} ref={sectionRef}>
      <div className="cielx-new-container">
        {sliderContent.map((item, index) => (
          <div className="row flex-align-center" style={{ alignItems: "center" }} key={index}>
            <div className="col-7 hidden"> {/* Apply the hidden class here */}
              <h4>{item?.title}</h4>
              <p style={{ whiteSpace: "pre-line", fontFamily: "Crimson Text" }}>
                {item?.description1}
              </p>
              <p style={{ fontFamily: "Crimson Text" }}>{item?.description2}</p>
              {btn !== "no" && (
                <Link to={item?.btnLink} className="custom-primary-btn mt-30">
                  {item?.btnText}
                </Link>
              )}
            </div>
            <div className="col-1"></div>
            <div className="col-4 hidden-image"> {/* Apply the hidden-image class here */}
              <img src={item?.image} alt={item?.title} />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AboutSection;

import React, { useEffect, useState } from "react";
import FutureDiv from "../../markup/element/BannerSlider/FutureDiv";
import FutureDivReverse from "../element/BannerSlider/FutureDivReverse";
import FooterHome from "../layout/footerHome";
import Header from "../layout/header10";
import { getBanners, getSectionContent, IMAGE_PREFIX } from "../server";

const Future = () => {
  const [banner, setBanner] = useState(null);
  const [viewportWidth, setViewportWidth] = useState(window.innerWidth);
  const [futureData, setFutureData] = useState(null);
  const [pageData1, setPageData1] = useState(null);
  const [pageData2, setPageData2] = useState(null);
  const [pageData3, setPageData3] = useState(null);
  const fetchFutureData = () => {
    getSectionContent({ type: "FUTURE" })
      .then((res) => {
        console.log("section :", res);
        if (res) {
          setFutureData(res?.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchPageData1 = () => {
    getSectionContent({ type: "CONTENT1" })
      .then((res) => {
        console.log("section :", res);
        if (res) {
          setPageData1(res?.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchPageData2 = () => {
    getSectionContent({ type: "CONTENT2" })
      .then((res) => {
        console.log("section :", res);
        if (res) {
          setPageData2(res?.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchPageData3 = () => {
    getSectionContent({ type: "CONTENT3" })
      .then((res) => {
        console.log("section :", res);
        if (res) {
          setPageData3(res?.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // Update the viewport width state on window resize
  const updateWindowDimensions = () => {
    setViewportWidth(window.innerWidth);
  };

  useEffect(() => {
    // Add event listener to handle resize
    window.addEventListener("resize", updateWindowDimensions);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", updateWindowDimensions);
    };
  }, []);

  useEffect(() => {
    getBanners({ type: "FUTURE" })
      .then((res) => {
        if (res) {
          setBanner(res?.data[0]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    fetchFutureData();
    fetchPageData1();
    fetchPageData2();
    fetchPageData3();
  }, []);

  // Define breakpoints
  const isMobile = viewportWidth <= 768;
  const isTablet = viewportWidth > 768 && viewportWidth <= 1024;
  const isDesktop = viewportWidth > 1024;

  return (
    <>
      <Header />
      <div
        style={{
          width: "100%",
          backgroundImage: futureData?.backgroundImage
            ? `url(${IMAGE_PREFIX}/${futureData?.backgroundImage?.url})`
            : "none",
          backgroundSize: "cover",
          backgroundPosition: "left center",
          position: "relative",
        }}
      >
        <div
          className="page-content home-slider-10"
          style={{
            marginLeft: isMobile ? 0 : isTablet ? 30 : 60,
            marginRight: isMobile ? 0 : isTablet ? 30 : 60,
          }}
        >
          <div
            className="dlab-bnr-inr overlay-black-middle bg-pt"
            style={{
              // backgroundImage: `url('http://143.198.195.72:8080/abc/${banner?.images[0]?.url}')`,
              backgroundImage: `url('https://rest.cielx.org/abc/${banner?.images[0]?.url}')`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              height: isMobile ? "25vh" : isTablet ? "45vh" : "85vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              className="container"
              style={{
                marginLeft: isMobile ? 20 : isTablet ? 80 : 132, // Adjust margin for tablet and mobile
              }}
            >
              <h1
                className="text-white"
                style={{
                  fontSize: isMobile ? 24 : isTablet ? 40 : 55, // Tablet-specific font size
                  marginTop: isMobile ? 100 : isTablet ? 150 : 250, // Adjust top margin for tablet
                  fontFamily: "Montserrat",
                  fontWeight: 500,
                  lineHeight: "normal",
                  textAlign: "left",
                  color: "#fff",
                  textDecoration: "underline",
                  textDecorationColor: "white",
                  textUnderlineOffset: isMobile ? 7 : isTablet ? 10 : 15, // Tablet-specific underline offset
                }}
              >
                {banner?.title}
              </h1>
            </div>
          </div>
          <div
            className="content-block"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              className="cielx-new-container"
              // style={{
              //   marginLeft: isMobile ? 40 : isTablet ? 60 : 160, // Adjust margin for tablet
              // }}
            >
              <div className="future-section-wrapper">
                <h3>{futureData?.mainTitle}</h3>

                <FutureDiv sectionData={pageData1} />

                <FutureDivReverse sectionData={pageData2} />

                <FutureDiv sectionData={pageData3} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <FooterHome />
    </>
  );
};

export default Future;

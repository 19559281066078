import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
import Slider from 'react-slick';
//import responsive from './slider-resonsive'
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Link } from 'react-router-dom';

const work1 = require('../../images/our-work/pic1.jpg');
const work2 = require('../../images/our-work/pic2.jpg');
const work3 = require('../../images/our-work/pic3.jpg');
const work4 = require('../../images/our-work/pic4.jpg');

const Testimonial1_content = [
  {
    thumb: require('./../../images/testimonials/pic1.jpg'),
    title: 'News & Events',
    designation: 'Student',
    description:
      'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the when an printer took a galley of type and scrambled it to make ',
  },
  {
    thumb: require('./../../images/testimonials/pic2.jpg'),
    title: 'Brochure',
    designation: 'Student',
    description:
      'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the when an printer took a galley of type and scrambled it to make ',
  },
  {
    thumb: require('./../../images/testimonials/pic3.jpg'),
    title: 'Leaflet',
    designation: 'Student',
    description:
      'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the when an printer took a galley of type and scrambled it to make ',
  },
  {
    thumb: require('./../../images/testimonials/pic1.jpg'),
    title: 'JSRM Journey',
    designation: 'Student',
    description:
      'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the when an printer took a galley of type and scrambled it to make ',
  },
  {
    thumb: require('./../../images/testimonials/pic1.jpg'),
    title: 'TVC',
    designation: 'Student',
    description:
      'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the when an printer took a galley of type and scrambled it to make ',
  },
  {
    thumb: require('./../../images/testimonials/pic1.jpg'),
    title: 'Gallery',
    designation: 'Student',
    description:
      'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the when an printer took a galley of type and scrambled it to make ',
  },
];

function Testimonial1({ data }) {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: false,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <>
      <Slider
        {...settings}
        className="testimonial-six dots-long d-primary btn-style-1"
      >
        {data &&
          data.map((item, id) => (
            <div className="section-full bg-white content-inner">
              <div className="container">
                <div className="row col-lg-12">
                  <div className="col-lg-12">
                    <div className="section-content box-sort-in button-example p-b0">
                      <div className="row">
                        <div className="col-lg-12 col-md-6 col-sm-6">
                          <div
                            className="dlab-box-bg m-b30 box-hover style3"
                            style={{
                              backgroundImage: `url(${`http://143.198.195.72:8080/abc/${item.icon.url}`})`,
                            }}
                          >
                            <div className="icon-bx-wraper center p-lr20 p-tb30">
                              <div className="text-primary m-b30">
                                <span className="icon-cell icon-sm">
                                  <i className="ti-ruler-pencil"></i>
                                </span>
                              </div>
                              <div className="icon-content">
                                <h5 className="dlab-tilte">{item.title}</h5>
                              </div>
                            </div>
                            <div className="icon-box-btn text-center">
                              <Link
                                to={'/news-events'}
                                className="site-button btn-block"
                              >
                                Read More
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </Slider>
    </>
  );
}
export default Testimonial1;

import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import AboutUs from "./pages/about-us";
import Audit from "./pages/auditComitee";
import BlogGrid2 from "./pages/blog-grid-2";
import Brochure from "./pages/brochure";
import Certification from "./pages/certification";
import Compliance from "./pages/compliance";
import ContactUs from "./pages/contact-us";
import Corporate from "./pages/corporate";
import CertificateDetails from "./pages/details/certificateDetails";
import GrowthTowardsNature from "./pages/details/GrowthTowardsNature";
import ProductDetails from "./pages/details/ProductDetails";
import ProductsPage from "./pages/details/ProductsPage";
import TestimonialDetails from "./pages/details/TestimonialDetails";
import Environmental from "./pages/environmental";
import Financial from "./pages/financial";
import Future from "./pages/future";
import FutureDynamic from "./pages/futureDynamic";
import HR from "./pages/hr";
import Index10 from "./pages/index-10";
import Jobs from "./pages/jobs";
import Journey from "./pages/journey";
import Key from "./pages/keyManagement";
import Leaflet from "./pages/leaflet";
import Mission from "./pages/mission";
import News from "./pages/news";
import Nomination from "./pages/nomination";
import OnlineCv from "./pages/onlineCV";
import OrderEdit from "./pages/orderEdit";
import OrderNow from "./pages/orderNow";
import PostSales from "./pages/postSales";
import PreSales from "./pages/preSales";
import Products from "./pages/products";
import QualityControl from "./pages/qualityControl";
import QualityTest from "./pages/qualityTest";
import Remarkable from "./pages/remarkable";
import ShopLogin from "./pages/shop-login";
import ShopWishlist from "./pages/shop-wishlist";
import Sister from "./pages/sister";
import Social from "./pages/social";
import SupplyChain from "./pages/supplyChain";
import Team1 from "./pages/team-1";
import Technological from "./pages/technological";
import TVC from "./pages/tvc";
import PrivateRoute from "./routes/PrivateRoute";
import ScrollToTop from "./ScrollToTop";

class Router extends Component {
  render() {
    return (
      //<BrowserRouter>
      <BrowserRouter basename={"/"}>
        <div className="page-wraper">
          <ScrollToTop />
          <Switch>
            <Route path="/" exact component={Index10} />
            <Route path="/about-1" component={AboutUs} />
            <Route path="/about-us" component={AboutUs} />
            <Route path="/future1" component={Future} />
            <Route path="/future" component={FutureDynamic} />
            <Route path="/mission" component={Mission} />
            <Route path="/corporate" component={Corporate} />
            <Route path="/board-of-directors" component={Team1} />
            <Route path="/nominatiom-and-renumeration" component={Nomination} />
            <Route path="/audit-commitee" component={Audit} />
            <Route path="/key-management" component={Key} />
            <Route path="/sister-concern" component={Sister} />
            <Route path="/products" component={Products} />
            <Route path="/quality-test" component={QualityTest} />
            <Route path="/quality-control" component={QualityControl} />
            <Route path="/certification" component={Certification} />
            <Route path="/pre-sales-service" component={PreSales} />
            <Route path="/post-sales-service" component={PostSales} />
            <Route path="/supply-chain" component={SupplyChain} />
            <Route path="/environmental" component={Environmental} />
            <Route path="/remarkable-customers" component={Remarkable} />
            <Route path="/financial" component={Financial} />
            <Route path="/social" component={Social} />
            <Route path="/technological" component={Technological} />
            <Route path="/compliance" component={Compliance} />
            <Route path="/hr-policy" component={HR} />
            <Route path="/available-jobs" component={Jobs} />
            <Route path="/news-events" component={BlogGrid2} />
            <Route path="/brochure" component={Brochure} />
            <Route path="/leaflet" component={Leaflet} />
            <Route path="/journey" component={Journey} />
            <Route path="/tvc" component={TVC} />
            <Route path="/testimonials" component={TestimonialDetails} />
            <Route path="/contact-us" component={ContactUs} />
            <Route path="/news" component={News} />
            <Route path="/onlinecv" component={OnlineCv} />
            <Route path="/login-page" component={ShopLogin} />
            <PrivateRoute path="/order-now" component={OrderNow} />
            <PrivateRoute path="/order-list" component={ShopWishlist} />
            <PrivateRoute path="/orderEdit/:id" component={OrderEdit} />
            <Route path="/certificate-details" component={CertificateDetails} />
            <Route
              path="/growthTowardsNature"
              component={GrowthTowardsNature}
            />
            <Route path="/all-products" component={ProductsPage} />
            <Route path="/product/:id" component={ProductDetails} />
            {/* <Route path="/blog-grid-2-sidebar" component={BlogGrid2Sidebar} />
            

            <Route path="/blog-grid-2-sidebar" component={BlogGrid2Sidebar} />
            <Route
              path="/blog-grid-2-sidebar-left"
              component={BlogGrid2SidebarLeft}
            />
            <Route path="/blog-grid-3" component={BlogGrid3} />
            <Route path="/blog-grid-3-sidebar" component={BlogGrid3Sidebar} />
            <Route
              path="/blog-grid-3-sidebar-left"
              component={BlogGrid3SidebarLeft}
            />
            <Route path="/blog-grid-4" component={BlogGrid4} />
            <Route path="/blog-half-img" component={BlogHalfImg} />
            <Route
              path="/blog-half-img-left-sidebar"
              component={BlogHalfImgSidebarLeft}
            />
            <Route
              path="/blog-half-img-sidebar"
              component={BlogHalfImgSidebar}
            />
            <Route path="/blog-large-img" component={BlogLargeImg} />
            <Route
              path="/blog-large-img-left-sidebar"
              component={BlogLargeImgSidebarLeft}
            />
            <Route
              path="/blog-large-img-sidebar"
              component={BlogLargeImgSidebar}
            />
            <Route path="/blog-single" component={BlogSingle} />
            <Route
              path="/blog-single-left-sidebar"
              component={BlogSingleSidebarLeft}
            />
            <Route path="/blog-single-sidebar" component={BlogSingleSidebar} />
            <Route path="/coming-soon-1" component={ComingSoon1} />

            <Route path="/contact-2" component={Contact2} />
            <Route path="/contact-3" component={Contact3} />
            <Route path="/contact-4" component={Contact4} />
            <Route path="/error-403" component={Error403} />
            <Route path="/error-404" component={Error404} />
            <Route path="/error-405" component={Error405} />
            <Route path="/faq-1" component={Faq1} />
            <Route path="/faq-2" component={Faq2} />
            <Route path="/help-desk" component={HelpDesk} />
            <Route path="/portfolio-details" component={PortfolioDetails} />
            <Route path="/portfolio-grid-2" component={PortfolioGrid2} />
            <Route path="/portfolio-grid-3" component={PortfolioGrid3} />
            <Route path="/portfolio-grid-4" component={PortfolioGrid4} />
            <Route path="/privacy-policy" component={PrivacyPolicy} />
            <Route path="/services-1" component={Services1} />
            <Route path="/services-2" component={Services2} />
            <Route path="/services-details" component={ServicesDetails} />
            <Route path="/shop-cart" component={ShopCart} />
            <Route path="/shop-checkout" component={ShopCheckout} />
            
            <Route
              path="/shop-product-details"
              component={shopProductDetails}
            />
            
            <Route path="/shop-sidebar" component={ShopSidebar} />
            
            <Route path="/shop" component={Shop} />
            <Route path="/team-1" component={Team1} />
            <Route path="/team-2" component={Team2} />
            <Route path="/index-2" component={Index2} />
            <Route path="/index-3" component={Index3} /> */}
            {/* <Route path="/index-4" component={Index4} />
            <Route path="/index-5" component={Index5} />
            <Route path="/index-6" component={Index6} />
            <Route path="/index-7" component={Index7} />
            <Route path="/index-8" component={Index8} />
            <Route path="/index-9" component={Index9} />
            <Route path="/index-10" component={Index10} />
            <Route path="/index-11" component={Index11} />
            <Route path="/index-12" component={Index12} />
            <Route path="/index-13" component={Index13} />
            <Route path="/index-14" component={Index14} />
            <Route path="/index-15" component={Index15} />
            <Route path="/shortcode-buttons" component={shortButtons} />
            <Route path="/shortcode-alert-box" component={shortAlertBox} />
            <Route
              path="/shortcode-icon-box-styles"
              component={shortIconBoxStyles}
            />
            <Route path="/shortcode-icon-box" component={shortIconBox} />
            <Route
              path="/shortcode-pricing-table"
              component={shortPricingTable}
            />
            <Route path="/shortcode-dividers" component={shortDividers} />
            <Route path="/shortcode-list-group" component={shortListGroup} />
            <Route
              path="/shortcode-title-separators"
              component={shortTitleSeparators}
            />
            <Route
              path="/shortcode-image-box-content"
              component={shortImageBoxContent}
            />
            <Route path="/shortcode-pagination" component={shortPagination} />
            <Route path="/shortcode-counters" component={shortCounters} />
            <Route
              path="/shortcode-testimonials"
              component={shortTestimonials}
            />
            <Route
              path="/shortcode-images-effects"
              component={shortImagesEffects}
            />
            <Route path="/shortcode-shop-widgets" component={shopWidgets} />
            <Route path="/shortcode-team" component={shortTeam} /> */}
          </Switch>
        </div>
      </BrowserRouter>
    );
  }
}

export default Router;

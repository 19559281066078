import React, { useEffect, useState } from "react";
import Counter from "./counter";
import ModalVideo from "react-modal-video";
import "react-modal-video/scss/modal-video.scss";
import { IMAGE_PREFIX } from "../server";

const HomeCounter = ({ backgroundImage, sectionData }) => {
  const mobilebg = require('../../images/background/counterbgmb.png')

  const [isVisible, setIsVisible] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // Track if mobile view
  useEffect(() => {
    // Check if screen size is mobile and update state
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const onScroll = () => {
      const element = document.querySelector('.home-counter-container');
      if (element) {
        const rect = element.getBoundingClientRect();
        if (rect.top <= window.innerHeight && rect.bottom >= 0) {
          setIsVisible(true); // Start animation when section is in view
        }
      }
    };
    window.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  const containerStyle = {
    opacity: isVisible ? 1 : 0,
    transform: isVisible ? 'translateY(0)' : 'translateY(-100px)',
    transition: 'all 2.5s ease',
  };

  return (
    <div
      className="text-white bg-img-fix counter-status-box"
      // style={{ backgroundImage: isMobile ? "url(" + mobilebg + ")" : "url(" + backgroundImage + ")" }}
      style={{ backgroundImage: sectionData?.backgroundImage ? `url(${IMAGE_PREFIX}/${sectionData?.backgroundImage?.url})` : "none" }}
    >
      <div
        className="home-counter-container"
        style={containerStyle}
      >
        <div className="row" style={{ display: "flex", justifyContent: "center" }}>

          <div
            className="col-md-2 col-lg-2 col-sm-4 wow fadeInUp counter-box-wrapper"
            data-wow-duration="2s"
            data-wow-delay="0.4s"
          >
            <div className="icon-bx-wraper center counter-style-5">
              <div className="icon-content">
                <div className="bg-primary"></div>
                <Counter count={Number(sectionData?.boxElements[0]?.title)} />
                <p style={{ fontFamily: "Crimson Text" }}>{sectionData?.boxElements[0]?.description}</p>
              </div>
            </div>
          </div>
          <div
            className="col-md-2 col-lg-2 col-sm-4 wow fadeInUp counter-box-wrapper"
            data-wow-duration="2s"
            data-wow-delay="0.6s"
          >
            <div className="icon-bx-wraper center counter-style-5">
              <div className="icon-content">
                <div className="bg-primary"></div>
                <Counter count={Number(sectionData?.boxElements[1]?.title)} />
                <p style={{ fontFamily: "Crimson Text" }}>{sectionData?.boxElements[1]?.description}</p>
              </div>
            </div>
          </div>
          <div
            className="col-md-2 col-lg-2 col-sm-4 wow fadeInUp counter-box-wrapper"
            data-wow-duration="2s"
            data-wow-delay="0.2s"
          >
            <div className="icon-bx-wraper center counter-style-5">
              <div className="icon-content">
                <div className="bg-primary"></div>
                <Counter count={Number(sectionData?.boxElements[2]?.title)} />
                <p style={{ fontFamily: "Crimson Text" }}>{sectionData?.boxElements[2]?.description}</p>
              </div>
            </div>
          </div>
          <div
            className="col-md-2 col-lg-2 col-sm-4 wow fadeInUp counter-box-wrapper"
            data-wow-duration="2s"
            data-wow-delay="0.6s"
          >
            <div className="icon-bx-wraper center counter-style-5">
              <div className="icon-content">
                <div className="bg-primary"></div>
                <div className="row" style={{ justifyContent: "center" }}>
                  <Counter count={Number(sectionData?.boxElements[3]?.title)} />
                  <span className="dlab-title counter">M</span>
                </div>

                <p style={{ fontFamily: "Crimson Text" }}>{sectionData?.boxElements[3]?.description}</p>
              </div>
            </div>
          </div>
          <div
            className="col-md-2 col-lg-2 col-sm-4 wow fadeInUp counter-box-wrapper"
            data-wow-duration="2s"
            data-wow-delay="0.6s"
          >
            <div className="icon-bx-wraper center counter-style-5">
              <div className="icon-content">
                <div className="bg-primary"></div>
                <Counter count={Number(sectionData?.boxElements[4]?.title)} />
                <p style={{ fontFamily: "Crimson Text" }}>{sectionData?.boxElements[4]?.description}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeCounter;

import React, { Component, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Header from "../layout/header10";
import Footer from "../layout/footer";
import Testimonial1 from "../element/testimonial1";
import HomeSlider from "../element/homeSlider2";
import {
  aboutSectionContentFirst,
  aboutUsPageAboutSection,
  aboutUsPageAboutSection2,
  homeSliderContent8,
} from "../element/SliderContent";
import AboutSection from "../element/aboutSection";
import AboutSectionReverse from "../element/aboutSectionReverse";
import FooterHome from "../layout/footerHome";
import { getBanners } from "../server";
import CommonBannerSlider from "../element/CommonBannerSlider";
var bnr3 = require("./../../images/banner/bnr3.jpg");
var bg1 = require("./../../images/background/bg-video.png");
var bg2 = require("./../../images/background/bg1.jpg");

const AboutUs = () => {
  const [banner, setBanner] = useState(null);
  useEffect(() => {
    getBanners({ type: "ABOUT_US" })
      .then((res) => {
        console.log("resAboutUs", res);
        if (res) {
          setBanner(res?.data[0]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const onScroll = () => {
      const element = document.querySelector('.vision-section-wrapper');
      if (element) {
        const rect = element.getBoundingClientRect();
        if (rect.top <= window.innerHeight && rect.bottom >= 0) {
          setIsVisible(true); // Start animation when section is in view
        }
      }
    };
    window.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  const containerStyle = {
    opacity: isVisible ? 1 : 0,
    transform: isVisible ? 'translateY(0)' : 'translateY(-100px)',
    transition: 'all 1.5s ease',
  };
  return (
    <>
      <Header />

      <div className="page-content bg-white home-slider-10">
        <CommonBannerSlider
          data={banner}
          contentWrapperCls="sliderStyle10"
          subTitleCls="text-primary"
          btnCls="text-primary"
          btnText="Continue The Journey"
        />
        <div className="content-block">
          <div className="section-full">
            <AboutSection
              data={aboutUsPageAboutSection}
              className="about-section-solid"
              btn="no"
            />
          </div>
          <div className="section-full">
            <div className="vision-section-wrapper" style={containerStyle}>
              <div className="cielx-new-container" style={{ margin: "auto" }}>
                <div className="row">
                  <div className="col-md-6 col-lg-4 col-sm-12">
                    <div className="about-card" >
                      <img
                        src={require("../../images/background/mission1.png")}
                        alt=""
                        style={{ width: 60, marginBottom: 24 }}
                      />
                      <h3>MISSION</h3>
                      <p style={{ fontFamily: "Crimson Text" }}>
                        Supplying the highest quality raw materials to the Ready-Made Garment (RMG) sector, ensuring that our clients can produce superior products with confidence and efficiency.
                      </p>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4 col-sm-12">
                    <div className="about-card" >
                      <img
                        src={require("../../images/background/eye1.png")}
                        alt=""
                        style={{ width: 60, marginBottom: 24 }}
                      />
                      <h3>VISION</h3>
                      <p style={{ fontFamily: "Crimson Text" }}>
                        Leading supplier of raw materials for the RMG sector, renowned for our innovation, quality, and integrity.
                      </p>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4 col-sm-12">
                    <div className="about-card">
                      <img
                        src={require("../../images/background/diamond1.png")}
                        alt=""
                        style={{ width: 60, marginBottom: 24 }}
                      />
                      <h3>VALUES</h3>
                      <p style={{ fontFamily: "Crimson Text" }}>
                        TA foundation of core values that reflect our dedication to quality, transparency, and sustainability.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="section-full">
            <AboutSectionReverse
              data={aboutUsPageAboutSection2}
              className="about-section-solid"
              btn="no"
            />
          </div>
        </div>
      </div>

      <FooterHome />
    </>
  );
};

export default AboutUs;
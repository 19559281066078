import React, { useEffect, useRef, useState } from 'react'

const MapSection = ({ className, data }) => {
  const sliderContent = data
  const pins = [
    {
      id: 1,
      top: '13%',
      left: '16%',
      text: 'Canada',
    },
    { id: 2, top: '20%', left: '47%', text: 'United Kingdom' },
    { id: 3, top: '28%', left: '56%', text: 'Turkey' },
    { id: 4, top: '38%', left: '65%', text: 'Pakistan' },
    { id: 5, top: '35%', left: '66%', text: 'Pakistan' },
    { id: 6, top: '34%', left: '68%', text: 'India' },
    { id: 7, top: '30%', left: '71%', text: 'China' },
    { id: 8, top: '32%', left: '75%', text: 'China' },
    { id: 9, top: '32%', left: '82%', text: 'South Korea' },
    { id: 10, top: '50%', left: '76%', text: 'Vietnam' },
    { id: 11, top: '46%', left: '68%', text: 'India' },
    { id: 12, top: '40%', left: '69%', text: 'India' },
    { id: 13, top: '40%', left: '67%', text: 'India' },
    { id: 14, top: '42%', left: '72%', text: 'Bangladesh' },

    // Add more pins as needed
  ]
  const [hoveredPin, setHoveredPin] = useState(null)
  const mapRef = useRef(null);
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            // Add 'zoom-show' class when the certificates are in view
            entry.target.classList.add('zoom-show');
          } else {
            // Optional: remove 'zoom-show' class when out of view
            entry.target.classList.remove('zoom-show');
          }
        });
      },
      { threshold: 0.1 } // Trigger when 10% of the element is visible
    );

    const elements = mapRef.current.querySelectorAll('.zoom-hidden');

    elements.forEach((el) => observer.observe(el));

    // Clean up observer on component unmount
    return () => {
      elements.forEach((el) => observer.unobserve(el));
    };
  }, [pins]);

  return (
    <div className={`about-section-wrapper ${className}`}>
      <div className="cielx-new-container" ref={mapRef}>
        {sliderContent.map((item, index) => (
          <div className="row flex-align-center zoom-hidden" style={{ alignItems: "center" }}>
            <div className="col-lg-4">
              <h4>{item?.title}</h4>
              <p style={{ fontFamily: "Crimson Text" }}>{item?.description1}</p>
              {/* <Link to={item?.btnLink} className="custom-primary-btn mt-30">
                  {item?.btnText}
                </Link> */}
            </div>
            <div className="col-lg-8">
              <img src={item?.image} alt="Map" className="map-image" />
              {pins.map((pin) => (
                <div
                  key={pin.id}
                  className="pin"
                  style={{ top: pin.top, left: pin.left }}
                  onMouseEnter={() => setHoveredPin(pin.id)}
                  onMouseLeave={() => setHoveredPin(null)}
                >
                  {hoveredPin === pin.id && (
                    <div className="pin-tooltip">{pin.text}</div>
                  )}
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default MapSection

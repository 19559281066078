import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const content = [
  {
    title: "Vulputate Mollis Ultricies Fermentum Parturient",
    description:
      "Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum. Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet risus. Cras justo odio, dapibus ac facilisis.",
    image: require("../../images/main-slider/slide11.jpg"),
    subTitle: "Luan Gjokaj",
    subTitle2: "https://i.imgur.com/JSW6mEk.png",
  },
  {
    title: "Vulputate Mollis Ultricies Fermentum Parturient",
    description:
      "Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum. Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet risus. Cras justo odio, dapibus ac facilisis.",
    image: require("../../images/main-slider/slide11.jpg"),
    subTitle: "Luan Gjokaj",
    subTitle2: "https://i.imgur.com/JSW6mEk.png",
  },
  {
    title: "Vulputate Mollis Ultricies Fermentum Parturient",
    description:
      "Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum. Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet risus. Cras justo odio, dapibus ac facilisis.",
    image: require("../../images/main-slider/slide11.jpg"),
    subTitle: "Luan Gjokaj",
    subTitle2: "https://i.imgur.com/JSW6mEk.png",
  },
  {
    title: "Vulputate Mollis Ultricies Fermentum Parturient",
    description:
      "Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum. Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet risus. Cras justo odio, dapibus ac facilisis.",
    image: require("../../images/main-slider/slide11.jpg"),
    subTitle: "Luan Gjokaj",
    subTitle2: "https://i.imgur.com/JSW6mEk.png",
  },
];
// const NextArrow = (props) => {
//   const { className, style, onClick } = props;
//   return (
//     <div
//       className={className}
//       style={{
//         ...style,
//         display: "block",
//         // right: "48%",
//         top: "50%",
//         transform: "translate(50%, -50%)",
//       }}
//       onClick={onClick}
//     />
//   );
// };

// const PrevArrow = (props) => {
//   const { className, style, onClick } = props;
//   return (
//     <div
//       className={className}
//       style={{
//         ...style,
//         display: "block",
//         // left: "48%",
//         top: "50%",
//         transform: "translate(-20%, -50%)",
//       }}
//       onClick={onClick}
//     />
//   );
// };

const NextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        right: "0px", // Adjust this value to control how far right the button is
        top: "55%",
        transform: "translate(0, -50%)", // Removed the horizontal translation to avoid centering
        zIndex: 1, // Ensure the arrow is above other content
      }}
      onClick={onClick}
    />
  );
};

const PrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        left: "30px", // Adjust this value to control how far left the button is
        top: "55%",
        transform: "translate(0, -50%)", // Removed the horizontal translation to avoid centering
        zIndex: 1, // Ensure the arrow is above other content
      }}
      onClick={onClick}
    />
  );
};

class ProductSlider2 extends Component {
  render() {
    const settings = {
      dots: false,
      infinite: true,
      autoplay: true,
      arrows: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplaySpeed: 4000,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
    };
    const productSliderContent = this.props.data || content;

    console.log(productSliderContent, "productSliderContent");
    return (
      <>
        <Slider {...settings} className="product-slider2">
          {productSliderContent.map((item, index) => (
            <div
              key={index}
              className={`slider-content-product item bg-product-${index + 1}`}
            >

            </div>
          ))}
        </Slider>
      </>
    );
  }
}
export default ProductSlider2;

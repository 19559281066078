import React, { useEffect, useRef, useState } from "react";
import { FaStar } from "react-icons/fa";
import { Link } from "react-router-dom";
import { getTestimonials } from "../server";
import TestimonialSlider from "./TestimonialSlider";

const TestimonialHome = () => {
  const [testimonials, setTestimonials] = useState(null);
  const testimonialContentRef = useRef(null); // Ref for testimonial section
  const [inView, setInView] = useState(false); // Track when the section is in view
  const [currentIndex, setCurrentIndex] = useState(0); // Track the current testimonial index
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // Track if mobile view

  // Fetch testimonials data
  useEffect(() => {
    getTestimonials()
      .then((res) => {
        if (res) {
          setTestimonials(res?.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // Observe when the section comes into view
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setInView(true); // Start animation when in view
          }
        });
      },
      { threshold: 0.1 } // Trigger when 10% of the section is visible
    );

    if (testimonialContentRef.current) {
      observer.observe(testimonialContentRef.current);
    }

    // Cleanup the observer on component unmount
    return () => {
      if (testimonialContentRef.current) {
        observer.unobserve(testimonialContentRef.current);
      }
    };
  }, []);

  // Autoplay functionality (only for mobile)
  useEffect(() => {
    if (isMobile && testimonials) {
      const interval = setInterval(() => {
        setCurrentIndex((prevIndex) =>
          prevIndex === testimonials.length - 1 ? 0 : prevIndex + 1
        );
      }, 5000); // Change slide every 5 seconds

      return () => clearInterval(interval); // Clear the interval on component unmount
    }
  }, [isMobile, testimonials]);

  // Handle window resize to detect mobile view
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Handle left-right button navigation for mobile
  const goToPrevious = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? testimonials.length - 1 : prevIndex - 1
    );
  };

  const goToNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === testimonials.length - 1 ? 0 : prevIndex + 1
    );
  };

  return (
    <div className="cielx-testimonial-container">
      <div className="cielx-testimonial-content" ref={testimonialContentRef}>
        <div className="cielx-testimonial-heading">Testimonials</div>
        {testimonials ? (
          isMobile ? (
            <div className="col-12 cielx-testimonial-outer-box">
              <div
                className="testimonial-slider"
                style={{ position: "relative" }}
              >
                {/* Display only one testimonial at a time based on the current index */}
                <div className="cielx-testimonial-box">
                  {testimonials.map((testimonial, index) => (
                    <div
                      key={index}
                      className={`cielx-testimonial-card-home ${
                        inView ? "cielx-testimonial-card-home-1" : ""
                      }`}
                      style={{
                        display: index === currentIndex ? "" : "none", // Show only the current testimonial
                      }}
                    >
                      <div className="cielx-testimonial-card-content">
                        <div className="star-desc">
                          {Array.from(
                            { length: testimonial?.rating },
                            (_, idx) => (
                              <FaStar key={idx} color="gold" />
                            )
                          )}
                          <div className="alignStretch">
                            <p className="textStyle">{testimonial.details}</p>
                          </div>
                        </div>
                      </div>
                      <div className="cielx-testimonial-card-content2">
                        <div className="cielx-testimonial-card-name">
                          <div className="name-designation">
                            <p className="name-text">{testimonial.name}</p>
                            <p className="designation-text">
                              {testimonial.designation}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>

                {/* Left-Right Navigation Buttons */}
                {/* <button
                  className="slider-button left"
                  onClick={goToPrevious}
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "10px",
                    transform: "translateY(-50%)",
                    background: "none",
                    border: "none",
                    fontSize: "24px",
                    cursor: "pointer",
                  }}
                >
                  &#8249;
                </button>
                <button
                  className="slider-button right"
                  onClick={goToNext}
                  style={{
                    position: "absolute",
                    top: "50%",
                    right: "10px",
                    transform: "translateY(-50%)",
                    background: "none",
                    border: "none",
                    fontSize: "24px",
                    cursor: "pointer",
                  }}
                >
                  &#8250;
                </button> */}
              </div>
            </div>
          ) : (
            // <div className="col-12 cielx-testimonial-outer-box">
            //   <div className="cielx-testimonial-box">
            //     {/* Card 1 */}
            //     <div
            //       className={`cielx-testimonial-card-home ${
            //         inView ? "cielx-testimonial-card-home-1" : ""
            //       }`}
            //       style={{ marginBottom: 60 }}
            //     >
            //       <div className="cielx-testimonial-card-content">
            //         <div className="star-desc">
            //           {Array.from(
            //             { length: testimonials[0]?.rating },
            //             (_, index) => (
            //               <FaStar key={index} color="gold" />
            //             )
            //           )}
            //           <div className="alignStretch">
            //             <p className="textStyle">{testimonials[0]?.details}</p>
            //           </div>
            //         </div>
            //       </div>
            //       <div className="cielx-testimonial-card-content2">
            //         <div className="cielx-testimonial-card-name">
            //           <div className="name-designation">
            //             <p className="name-text">{testimonials[0]?.name}</p>
            //             <p className="designation-text">
            //               {testimonials[0]?.designation}
            //             </p>
            //           </div>
            //         </div>
            //       </div>
            //     </div>

            //     {/* Card 2 */}
            //     <div
            //       className={`cielx-testimonial-card-home ${
            //         inView ? "cielx-testimonial-card-home-2" : ""
            //       }`}
            //       style={{ marginTop: 60 }}
            //     >
            //       <div className="cielx-testimonial-card-content">
            //         <div className="star-desc">
            //           {Array.from(
            //             { length: testimonials[1]?.rating },
            //             (_, index) => (
            //               <FaStar key={index} color="gold" />
            //             )
            //           )}
            //           <div className="alignStretch">
            //             <p className="textStyle">{testimonials[1]?.details}</p>
            //           </div>
            //         </div>
            //       </div>
            //       <div className="cielx-testimonial-card-content2">
            //         <div className="cielx-testimonial-card-name">
            //           <div className="name-designation">
            //             <p className="name-text">{testimonials[1]?.name}</p>
            //             <p className="designation-text">
            //               {testimonials[1]?.designation}
            //             </p>
            //           </div>
            //         </div>
            //       </div>
            //     </div>
            //   </div>

            //   <div className="cielx-testimonial-box">
            //     {/* Card 3 */}
            //     <div
            //       className={`cielx-testimonial-card-home ${
            //         inView ? "cielx-testimonial-card-home-3" : ""
            //       }`}
            //       style={{ marginBottom: 60 }}
            //     >
            //       <div className="cielx-testimonial-card-content">
            //         <div className="star-desc">
            //           {Array.from(
            //             { length: testimonials[2]?.rating },
            //             (_, index) => (
            //               <FaStar key={index} color="gold" />
            //             )
            //           )}
            //           <div className="alignStretch">
            //             <p className="textStyle">{testimonials[2]?.details}</p>
            //           </div>
            //         </div>
            //       </div>
            //       <div className="cielx-testimonial-card-content2">
            //         <div className="cielx-testimonial-card-name">
            //           <div className="name-designation">
            //             <p className="name-text">{testimonials[2]?.name}</p>
            //             <p className="designation-text">
            //               {testimonials[2]?.designation}
            //             </p>
            //           </div>
            //         </div>
            //       </div>
            //     </div>

            //     {/* Card 4 */}
            //     <div
            //       className={`cielx-testimonial-card-home ${
            //         inView ? "cielx-testimonial-card-home-4" : ""
            //       }`}
            //       style={{ marginTop: 60 }}
            //     >
            //       <div className="cielx-testimonial-card-content">
            //         <div className="star-desc">
            //           {Array.from(
            //             { length: testimonials[3]?.rating },
            //             (_, index) => (
            //               <FaStar key={index} color="gold" />
            //             )
            //           )}
            //           <div className="alignStretch">
            //             <p className="textStyle">{testimonials[3]?.details}</p>
            //           </div>
            //         </div>
            //       </div>
            //       <div className="cielx-testimonial-card-content2">
            //         <div className="cielx-testimonial-card-name">
            //           <div className="name-designation">
            //             <p className="name-text">{testimonials[3]?.name}</p>
            //             <p className="designation-text">
            //               {testimonials[3]?.designation}
            //             </p>
            //           </div>
            //         </div>
            //       </div>
            //     </div>
            //   </div>

            //   {/* Additional Cards */}
            // </div>
            <TestimonialSlider testimonials={testimonials} />
          )
        ) : null}

        {/* <Link to="/testimonials" className="cielx-testimonial-button">
          <div className="cielx-testimonial-button-text">See more</div>

        </Link> */}
        <Link to="/testimonials" className="custom-primary-btn">
          See more
        </Link>
      </div>
    </div>
  );
};

export default TestimonialHome;

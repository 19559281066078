import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Header from '../layout/header10'
import Footer from '../layout/footer4'
import Testimonial8 from '../element/testimonial8'
import ExibitionSlider1 from '../element/exibitionSlider1'
import {
  exibitionContent2,
  blogContent4,
  homeSliderContent8,
  aboutSectionReverseContent,
  aboutSectionContentFirst,
  productSliderContent,
  certificationData,
  aboutSection2,
  officeMapContent,
  testimonialContent,
} from '../element/SliderContent'
import BlogSlider1 from '../element/blogSlider1'
import HomeSlider from '../element/homeSlider2'
import Gallery2 from '../element/gallery2'
import CounterSection from '../element/counterSection'
import ScrollToTop from '../element/scrollToTop'
import Team6 from '../element/team6'
import HomeCounter from '../element/homeCounter'
import FlipCard from '../element/flipCard'
import AboutSection from '../element/aboutSection'
import AboutSectionReverse from '../element/aboutSectionReverse'
import ProductSlider2 from '../element/productSlider2'

import Testmonial3 from '../element/testmonial3'
import Testmonial4 from '../element/testmonial4'
import Testmonial5 from '../element/testmonial5'
import Testimonial1 from './../element/testimonial1'
import Testimonial2 from '../element/testmonial2'
import Testimonial6 from '../element/testimonial6'
import Testimonial9 from '../element/testimonial9'
import Testimonial10 from '../element/testimonial10'
import Testimonial11 from '../element/testimonial11'
import Testimonial12 from '../element/testimonial12'
import LogoSection from '../element/logoSection'
import TestimonialHome from '../element/testimonialHome'
import Footer4 from '../layout/footerHome'
import FooterHome from '../layout/footerHome'
import MapSection from '../element/MapSection'
import CertificateSection from '../element/certificateSection'
import { getBanners } from '../server'
import BannerSlider from '../element/BannerSlider/BannerSlider'
// import '../../css/skin/skin-8.min.css';

const images = [
  {
    id: 1,
    name: 'healthy',
    title: 'Solid Wastes',
    imgUrl: require('../../images/gallery/leather/pic1.jpg'),
  },
  {
    id: 2,
    name: 'food',
    title: 'Leather Products',
    imgUrl: require('../../images/gallery/leather/pic2.jpg'),
  },
  {
    id: 3,
    name: 'organic',
    title: 'Leather processing',
    imgUrl: require('../../images/gallery/leather/pic3.jpg'),
  },
  {
    id: 4,
    name: 'organic',
    title: 'Industrial Apartment',
    imgUrl: require('../../images/gallery/leather/pic4.jpg'),
  },
]
const bg = require('../../images/background/counterBg.png')

const Index10 = () => {
  const [banner, setBanner] = useState(null);
  useEffect(() => {
    getBanners({ type: "HOME" })
      .then((res) => {
        console.log("resAboutUs", res);
        if (res) {
          setBanner(res?.data[0]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const onScroll = () => {
      const element = document.querySelector('.cielx-second-container');
      if (element) {
        const rect = element.getBoundingClientRect();
        if (rect.top <= window.innerHeight && rect.bottom >= 0) {
          setIsVisible(true); // Start animation when section is in view
        }
      }
    };
    window.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  const containerStyle = {
    opacity: isVisible ? 1 : 0,
    transform: isVisible ? 'translateY(0)' : 'translateY(-100px)',
    transition: 'all 2.5s ease',
  };
  return (
    <>
      <Header />

      <div className='page-content bg-white home-slider-10'>

        <BannerSlider
          slides={banner}
        />
        <div className='content-block'>

          <div className='home-second-section-wrapper'>
            <div className='cielx-second-container' style={containerStyle}>
              <div className='home-second-single'>
                <div className='second-card-wrapper'>
                  <img
                    src={require('../../images/background/honesty.png')}
                  />
                  <h4>INTEGRITY</h4>
                  <p>
                    Rooted in unwavering integrity, the company honors its
                    commitment to clients, partners, and stakeholders,
                    ensuring that trust is the cornerstone of every
                    relationship. With transparency as its guiding light, this
                    steadfast dedication propels the company forward,
                    inspiring a future grounded in ethical excellence.
                  </p>
                </div>

                <div className='second-card-wrapper'>
                  <img
                    src={require('../../images/background/dedication.png')}
                  />
                  <h4>DEDICATION</h4>
                  <p>
                    Our commitment to clients is driven by constant focus,
                    powerful motivation, and heightened anticipation, fueling
                    our relentless pursuit of perfection. We are dedicated to
                    delivering excellence in every project, ensuring our
                    clients receive nothing but the best.
                  </p>
                </div>

                <div className='second-card-wrapper'>
                  <img src={require('../../images/background/quality.png')} />
                  <h4>EXCELLENCE</h4>
                  <p>
                    This company is committed to upholding unparalleled
                    professionalism, providing our clients with products and
                    support that adhere to the most rigorous quality
                    benchmarks. We earnestly aim to exceed expectations,
                    delivering excellence in every aspect.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className='section-full'>
            <AboutSection
              data={aboutSectionContentFirst}
              className='about-section-solid'
            />
          </div>
          <div className='counter-wrapper'>
            <HomeCounter backgroundImage={bg} />
          </div>
          <div className='flip-card-section-wrapper'>
            <FlipCard />
          </div>
          <div className='section-full'>
            <AboutSectionReverse
              data={aboutSectionReverseContent}
              className='about-section-solid'
            />
          </div>
          <div className='product-small-section'>
            <div className='cielx-new-container'>
              <div className='row product-small-row'>
                <div className='col-lg-6' style={{ flexDirection: "column", marginTop: 40 }}>
                  <h3 style={{ fontFamily: "Montserrat" }}>Our Products</h3>
                  <p style={{ fontFamily: "Crimson Text" }}>Built For Tomorrow </p>
                </div>
                <div className='col-lg-6 pss-btn'>
                  <Link to='/all-products' className='custom-primary-btn mt-30'>
                    See More
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <ProductSlider2
            data={productSliderContent}
            contentWrapperCls='product-slider-wrapper'
            subTitleCls='text-primary'
            btnCls='text-primary'
            btnText='Continue The Journey'
          />
          <div className='section-full'>
            <CertificateSection
              data={certificationData}
              className='about-section-linear'
            />
          </div>
          <div className='section-full logo-section-main-wrapper'>
            <LogoSection />
          </div>
          <div className='section-full'>
            <AboutSection
              data={aboutSection2}
              className='about-section-solid'
            />
          </div>
          {/* <div className='section-full testimonial-section-wrapper'>
            <TestimonialHome />
          </div> */}
          <TestimonialHome />
          <div className='section-full'>
            <MapSection
              data={officeMapContent}
              className='about-section-solid'
            />
          </div>

        </div>
      </div>
      <FooterHome />
      <ScrollToTop className='style3' />
    </>
  )
}

export default Index10

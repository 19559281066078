import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import emailjs from 'emailjs-com';

const bg = require('../../images/background/bg3.png');
// style="background-image:url(images/background/bg3.png); background-size: cover;"

const Footer4 = () => {
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    //emailjs.sendForm('YOUR_SERVICE_ID', 'YOUR_TEMPLATE_ID', form.current, 'YOUR_USER_ID')
    emailjs
      .sendForm(
        'YOUR_SERVICE_ID',
        'YOUR_TEMPLATE_ID',
        e.target,
        'd9b2e0f5fc72cb94792110e8ff2028f3-us16'
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  };
  return (
    <>
      <footer className="site-footer">
        <div
          className="footer-top"
          style={{ backgroundImage: 'url(' + bg + ')' }}
        >
          <div className="container">
            <div className="row">
              <div className="col-md-3 col-5 col-xl-2 col-lg-2 col-sm-6 footer-col-4">
                <div className="widget widget_services border-0">
                  <h5 className="footer-title text-white">Company</h5>
                  <ul>
                    <li>
                      <a href="/about-us">About Us</a>
                    </li>
                    <li>
                      <Link to="/products">Products & Services</Link>
                    </li>

                    <li>
                      <Link to="/news"> News & Articles</Link>
                    </li>
                    <li>
                      <Link to="/environmental">Sustainability </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-3 col-7 col-xl-2 col-lg-3 col-sm-6 footer-col-4">
                <div className="widget widget_services border-0">
                  <h5 className="footer-title text-white">Useful Link</h5>
                  <ul>
                    {/* <li>
                      <Link to="">Help Desk </Link>
                    </li> */}

                    <li>
                      <a href="/contact-1">Contact Us</a>
                    </li>
                    <li>
                      <Link to="/products">Products</Link>
                    </li>
                    <li>
                      <Link to="/board-of-directors">Team</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-6 col-xl-4 col-lg-3 col-sm-6 footer-col-4">
                <div className="widget widget_getintuch">
                  <h5 className="footer-title text-white">Contact us</h5>
                  <ul>
                    <li>
                      <i className="ti-location-pin"></i>
                      <strong>address</strong>Mirpur DOHS, Avenue 11
Dhaka, Bangladesh.
                    </li>
                    <li>
                      <i className="ti-mobile"></i>
                      <strong>phone</strong>+880 1717-855488
                    </li>
                    <li>
                      <i className="ti-email"></i>
                      <strong>email</strong>fuad@cielx.org
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-12 col-xl-4 col-lg-4 col-sm-6 footer-col-4 ">
                <div className="widget">
                  <h5 className="footer-title text-white">Follow Us</h5>
                  <p className="text-capitalize m-b20"></p>

                  <ul className="list-inline m-a0">
                    <li>
                      <a
                        href="#"
                        className="site-button facebook circle "
                      >
                        <i className="fa fa-facebook"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="#"
                        className="site-button youtube circle "
                      >
                        <i
                          className="fa fa-youtube"
                          style={{
                            color: 'white',
                          }}
                        ></i>
                      </a>
                    </li>
                    {/* <li>
                      <Link to="#" className="site-button linkedin circle ">
                        <i className="fa fa-linkedin"></i>
                      </Link>
                    </li>
                    <li>
                      <Link to="#" className="site-button instagram circle ">
                        <i className="fa fa-instagram"></i>
                      </Link>
                    </li> */}
                    <li>
                      <a
                        href="#"
                        className="site-button whatsapp circle "
                      >
                        <i
                          className="fa fa-whatsapp"
                          style={{
                            color: 'white',
                          }}
                        ></i>
                      </a>
                    </li>
                    {/* <li>
                      <Link to="#" className="site-button twitter circle ">
                        <i className="fa fa-twitter"></i>
                      </Link>
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="footer-bottom">
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-sm-6 text-left ">
                {' '}
                <span>Copyright © 2023 CIELX</span>{' '}
              </div>
              {/* <div className="col-md-6 col-sm-6 text-right ">
                <div className="widget-link ">
                  <ul>
                    <li>
                      <Link to=""> About</Link>
                    </li>
                    <li>
                      <Link to=""> Help Desk</Link>
                    </li>
                    <li>
                      <Link to=""> Privacy Policy</Link>
                    </li>
                  </ul>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};
export default Footer4;

import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import emailjs from "emailjs-com";

const bg = require("../../images/background/bg3.png");
// style="background-image:url(images/background/bg3.png); background-size: cover;"

const FooterHome = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 480); // Initial state

  // Update the isMobile state on window resize
  const updateWindowDimensions = () => {
    setIsMobile(window.innerWidth <= 480);
  };

  useEffect(() => {
    // Add event listener to handle resize
    window.addEventListener("resize", updateWindowDimensions);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", updateWindowDimensions);
    };
  }, []);

  const sendEmail = (e) => {
    e.preventDefault();

    //emailjs.sendForm('YOUR_SERVICE_ID', 'YOUR_TEMPLATE_ID', form.current, 'YOUR_USER_ID')
    emailjs
      .sendForm(
        "YOUR_SERVICE_ID",
        "YOUR_TEMPLATE_ID",
        e.target,
        "d9b2e0f5fc72cb94792110e8ff2028f3-us16"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  };
  return (
    <>
      <footer className="site-footer">
        <div className="footer-top-custom">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 single-footer-column">
                <img src={require("../../images/background/call.png")} />
                <a href="tel:+8801717855488" >+880 1717-855488</a>
              </div>
              <div className="col-lg-4 single-footer-column2">
                <img src={require("../../images/background/logo-footer.png")} style={{ width: "50%" }} />

                <div style={{
                  display: 'inline-block',
                  width: "80%",
                  borderBottom: '3px solid white',
                  paddingBottom: '8px',
                  marginBottom: "15px"
                }}><img src={require("../../images/background/map-footer.png")} style={{ height: '100%' }} /></div>
                <h4>CIELX Limited.</h4>
                <a href="https://www.google.com/maps/place/23%C2%B050'18.0%22N+90%C2%B022'34.0%22E/@23.8383333,90.3761111,17z/data=!3m1!4b1!4m4!3m3!8m2!3d23.8383333!4d90.3761111?entry=ttu&g_ep=EgoyMDI0MDgyNi4wIKXMDSoASAFQAw%3D%3D" >
                  House 996 (1st floor), Road: 9/A, Avenue: 11, Mirpur DOHS,
                  Pallabi, Dhaka- 1216, Bangladesh.
                </a>
              </div>
              <div className="col-lg-4 single-footer-column2">
                <img src={require("../../images/background/mail.png")} style={{ marginTop: isMobile ? 10 : 80 }} />
                <a href="mailto:fuad@cielx.org">fuad@cielx.org</a>
                <a href="https://www.linkedin.com/company/cielx/" style={{ marginTop: 20 }}><img src={require("../../images/background/linkedin.png")} style={{ width: 40 }} /></a>
              </div>
            </div>
          </div>
        </div>

        <div className="footer-bottom" style={{ height: 54, display: "flex", alignItems: "center" }}>
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-sm-6 text-left ">
                {" "}
                <span className="footer-last-text">2024 by CIELX, All rights reserved.</span>{" "}
              </div>
              <div className="col-md-6 col-sm-6 text-right ">
                {" "}
                <span className="footer-last-text">Developed by Sweet iTech Team</span>{" "}
              </div>
              {/* <div className="col-md-6 col-sm-6 text-right ">
                <div className="widget-link ">
                  <ul>
                    <li>
                      <Link to=""> About</Link>
                    </li>
                    <li>
                      <Link to=""> Help Desk</Link>
                    </li>
                    <li>
                      <Link to=""> Privacy Policy</Link>
                    </li>
                  </ul>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};
export default FooterHome;

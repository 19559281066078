import React, { Component } from "react";
// import { Link } from 'react-router-dom';

// import Slider from "react-animated-slider";
import Slider from "react-slick";
// import "react-animated-slider/build/horizontal.css";
// import "../../css/slider-animations.css";
// import "../../css/homeslider.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const content = [
  {
    title: "Vulputate Mollis Ultricies Fermentum Parturient",
    description:
      "Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum. Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet risus. Cras justo odio, dapibus ac facilisis.",
    image: require("../../images/main-slider/slide11.jpg"),
    subTitle: "Luan Gjokaj",
    subTitle2: "https://i.imgur.com/JSW6mEk.png",
  },
  {
    title: "Vulputate Mollis Ultricies Fermentum Parturient",
    description:
      "Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum. Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet risus. Cras justo odio, dapibus ac facilisis.",
    image: require("../../images/main-slider/slide11.jpg"),
    subTitle: "Luan Gjokaj",
    subTitle2: "https://i.imgur.com/JSW6mEk.png",
  },
  {
    title: "Vulputate Mollis Ultricies Fermentum Parturient",
    description:
      "Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum. Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet risus. Cras justo odio, dapibus ac facilisis.",
    image: require("../../images/main-slider/slide11.jpg"),
    subTitle: "Luan Gjokaj",
    subTitle2: "https://i.imgur.com/JSW6mEk.png",
  },
  {
    title: "Vulputate Mollis Ultricies Fermentum Parturient",
    description:
      "Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum. Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet risus. Cras justo odio, dapibus ac facilisis.",
    image: require("../../images/main-slider/slide11.jpg"),
    subTitle: "Luan Gjokaj",
    subTitle2: "https://i.imgur.com/JSW6mEk.png",
  },
];

class HomeSlider2 extends Component {
  render() {
    const settings = {
      dots: true,
      arrows: true,
      infinite: true,
      autoplay: false,
    };
    const sliderContent = this.props.data || content;
    const { btnText } = this.props;
    const contentCls = this.props.contentWrapperCls || "";
    const subTitleCls = this.props.subTitleCls || "";
    //const btnCls = this.props.btnCls || ""
    console.log(sliderContent, "slidercontent");
    return (
      <>
        <Slider {...settings} className="slider-wrapper hero-slider">

          {sliderContent?.images?.map((item, index) => (
            <div key={index} className={`slider-content item bg-${index}`} style={{
              backgroundImage: `url('http://143.198.195.72:8080/abc/${item?.url}')`,
              // backgroundImage: `url("../../images/main-slider/homeSlide.jpg")`,
            }}>
              <div className={`inner ${contentCls}`}>

                {sliderContent?.title && (
                  <p className={`subTitle1 ${subTitleCls}`}>{sliderContent?.title}</p>
                )}
                <h1>{sliderContent?.body}</h1>

              </div>
            </div>
          ))}
        </Slider>
      </>
    );
  }
}
export default HomeSlider2;

import React, { Component, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Header from '../../layout/header10'
import Footer from '../../layout/footer'
import Testimonial1 from '../../element/testimonial1'
import HomeSlider from '../../element/homeSlider2'
import {
    aboutSectionContentFirst,
    aboutUsPageAboutSection,
    aboutUsPageAboutSection2,
    homeSliderContent8
} from '../../element/SliderContent'
import AboutSection from '../../element/aboutSection'
import AboutSectionReverse from '../../element/aboutSectionReverse'
import FooterHome from '../../layout/footerHome'
import { getBanners } from '../../server'
import CommonBannerSlider from '../../element/CommonBannerSlider'
import { NatureSection } from '../../element/NatureSection'

const GrowthTowardsNature = () => {
    const [banner, setBanner] = useState(null)
    useEffect(() => {
        getBanners({ type: 'GROWTH_TOWARDS_NATURE' })
            .then(res => {
                console.log('resAboutUs', res)
                if (res) {
                    setBanner(res?.data[0])
                }
            })
            .catch(err => {
                console.log(err)
            })
    }, [])
    return (
        <>
            <Header />

            <div
                className='page-content home-slider-10'
                style={{ backgroundColor: '#E1E1E1' }}
            >
                <CommonBannerSlider
                    data={banner}
                    contentWrapperCls='sliderStyle10'
                    subTitleCls='text-primary'
                    btnCls='text-primary'
                    btnText='Continue The Journey'
                />
                <div className='content-block'>
                    <div className={`about-section-wrapper about-section-solid`}>
                        <div className='cielx-new-container'>
                            <div className='growth-div-one'>
                                <img
                                    className='future-traced'
                                    alt='Future traced'
                                    src={require('../../../images/background/growth1.png')}
                                />
                                <div className='div'>
                                    <div className='text-wrapper'>Green Evolution</div>
                                    <p className='cielx-stands-as-a'>
                                        Cielx stands as a beacon of unwavering dedication to its
                                        cherished clientele, weaving a future where customer care
                                        and punctuality are paramount. Our commitment to delivering
                                        excellence is matched only by our resolute stance on
                                        environmental stewardship, ensuring that every product we
                                        offer reflects our ethos of integrity and innovation.
                                        Embrace the opportunity to partner with us, and experience a
                                        future where your needs are meticulously met and timely
                                        delivered, reflecting the very essence of our uncompromising
                                        standards. With Cielx, you choose a path to a prosperous and
                                        sustainable future, where your satisfaction is our highest
                                        priority.
                                        <br />
                                        Cielx, as a forward-thinking company, has embraced
                                        sustainability as a guiding principle to forge a harmonious
                                        relationship with nature. Choosing sustainability is not
                                        merely a strategy but a commitment to ensuring that growth
                                        and development are achieved in tandem with environmental
                                        preservation. By prioritizing green initiatives, We seek to
                                        minimize ecological impact, conserve resources, and foster a
                                        healthy, vibrant environment for future generations. This
                                        dedication to sustainability reflects a profound
                                        understanding of the interconnectedness between economic
                                        progress and ecological well-being, positioning Cielx as a
                                        beacon of responsible growth in a rapidly evolving world.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div
                        className={`about-section-wrapper about-section-solid`}
                        style={{ backgroundColor: 'rgba(205 ,215, 219 , 0.84)' }}
                    >
                        <div className='cielx-new-container'>
                            <NatureSection />
                        </div>
                    </div>

                    <div className={`about-section-wrapper about-section-solid`}>
                        <div className='cielx-new-container'>
                            <div className='growth-div-two'>
                                <div className='div'>
                                    <p className='text-wrapper'>Committed to a Greener Path</p>
                                    <p className='at-cielx-our'>
                                        At Cielx, our commitment to sustainability is more than just
                                        a business strategy—it&#39;s a guiding principle that shapes
                                        every aspect of our operations. We believe that in order to
                                        create a better future, businesses must lead the way in
                                        adopting eco-friendly practices. Our dedication to a greener
                                        path is evident in everything we do, from the products we
                                        create to the policies we uphold.
                                        <br />
                                        <br />
                                        Nature is at the heart of our sustainability efforts. We
                                        recognize that the natural world is not only a source of
                                        inspiration but also a vital resource that must be
                                        protected. This understanding drives our commitment to
                                        reducing waste, conserving resources, and promoting
                                        biodiversity in all our projects.
                                        <br />
                                        <br />
                                        <img
                                            className='image'
                                            alt='Image'
                                            src={require('../../../images/background/glove.png')}
                                        />
                                        <br />
                                        <br />
                                        At Cielx, we embrace the beauty and power of nature,
                                        integrating green spaces into our designs and operations.
                                        Whether through the use of renewable energy, sustainable
                                        materials, or eco-friendly technologies, we are constantly
                                        seeking ways to reduce our impact on the environment and
                                        contribute positively to the ecosystems around us.
                                        <br />
                                        <br />A key aspect of our sustainability mission is our
                                        dedication to the principles of the circular economy. We
                                        believe that the traditional, linear model of production and
                                        consumption—where resources are used and then discarded—must
                                        be replaced with a more sustainable approach. At Cielx, we
                                        understand that the journey towards sustainability is
                                        ongoing. We are constantly innovating, learning, and
                                        evolving to meet the challenges of a changing world.
                                        <br />
                                        <br />
                                        Our vision is to create a future where businesses and nature
                                        thrive together, where economic success is achieved without
                                        compromising the health of our planet. By staying true to
                                        our commitment to a greener path, we are confident that we
                                        can help build a more sustainable world for generations to
                                        come.
                                    </p>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <FooterHome />
        </>
    )
}

export default GrowthTowardsNature

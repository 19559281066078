import React, { useEffect, useState } from "react";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps";
import Header from "../layout/header10";
import CommonBannerSlider from "../element/CommonBannerSlider";
import FooterHome from "../layout/footerHome";
import { getBanners, getContactUs } from "../server";

const extractCoordinatesFromGoogleMapsURL = (url) => {
  const regex = /@(-?\d+\.\d+),(-?\d+\.\d+)/; // Matches @lat,lng in Google Maps URLs
  const matches = url.match(regex);

  if (matches) {
    return {
      lat: parseFloat(matches[1]),
      lng: parseFloat(matches[2]),
    };
  }
  return null; // Return null if coordinates can't be extracted
};

function ContactUs() {
  const [banner, setBanner] = useState(null);
  const [viewportWidth, setViewportWidth] = useState(window.innerWidth);
  const [footerData, setFooterData] = useState(null);
  const [mapCoordinates, setMapCoordinates] = useState(null);
  const fetchSectionData = () => {
    getContactUs()
      .then((res) => {
        if (res) {
          setFooterData(res?.data[0]);

          // Extract coordinates from the addressLink
          const coordinates = extractCoordinatesFromGoogleMapsURL(res?.data[0]?.footerElements[0]?.addressLink);
          setMapCoordinates(coordinates);  // Set coordinates for the map
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // Update viewport width state on window resize
  const updateWindowDimensions = () => {
    setViewportWidth(window.innerWidth);
  };


  useEffect(() => {

    window.addEventListener("resize", updateWindowDimensions);
    return () => {
      window.removeEventListener("resize", updateWindowDimensions);
    };
  }, []);

  useEffect(() => {
    getBanners({ type: "CONTACT" })
      .then((res) => {
        if (res) {
          setBanner(res?.data[0]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    fetchSectionData()
  }, []);

  // Define breakpoints
  const isMobile = viewportWidth <= 480;
  const isTablet = viewportWidth > 480 && viewportWidth <= 1024;

  const MyMapComponent = withScriptjs(
    withGoogleMap((props) => {
      const { lat, lng } = props.coordinates || { lat: 23.838333, lng: 90.376111 }; // Default coordinates if no coordinates are passed
      return (
        <GoogleMap
          google={props.google}
          defaultZoom={18}
          defaultCenter={{ lat, lng }}
        >
          <Marker key={1} position={{ lat, lng }} />
        </GoogleMap>
      );
    })
  );

  return (
    <>
      <Header />
      <div className="home-slider-10">
        <CommonBannerSlider
          data={banner}
          contentWrapperCls="sliderStyle10"
          subTitleCls="text-primary"
          btnCls="text-primary"
          btnText="Continue The Journey"
        />

        <div className="section-full bg-grey" style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}>
          <div
            className="cielx-new-container"
          >
            <div className="dlab-bnr-inr-entry page-header-text contact-page-header">
              <h3>{footerData?.title}</h3>
              <p>
                {footerData?.description}
              </p>
            </div>
          </div>
        </div>

        <div className="bg-grey contact-map-wrapper">
          <div
            className="container contact-container"
            style={{
              padding: isMobile ? "20px" : isTablet ? "40px" : "60px",
            }}
          >
            <div className="row contact-row">
              <div className="col-lg-4 col-md-6 col-sm-12 m-b30 single-contact-wrapper">
                <div className="single-contact">
                  <div className="icon-wrapper">
                    <i className="ti-email"></i>
                  </div>
                  <h3>Email</h3>
                  <p>{footerData?.footerElements[0]?.email}</p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 m-b30 single-contact-wrapper">
                <div className="single-contact">
                  <div className="icon-wrapper">
                    <i className="ti-location-pin"></i>
                  </div>
                  <h3>Address</h3>
                  <p>
                    {footerData?.footerElements[0]?.address}
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 m-b30 single-contact-wrapper">
                <div className="single-contact">
                  <div className="icon-wrapper">
                    <i className="ti-email"></i>
                  </div>
                  <h3>Whatsapp</h3>
                  <p>{footerData?.footerElements[0]?.phone}</p>
                </div>
              </div>
            </div>
          </div>

          <div
            className="custom-contact-container"
            style={{
              padding: isMobile ? "20px" : isTablet ? "40px" : "60px",
            }}
          >
            {mapCoordinates && (
              <MyMapComponent
                isMarkerShown
                coordinates={mapCoordinates}  // Pass the coordinates
                googleMapURL={`https://maps.googleapis.com/maps/api/js?key=AIzaSyB78hfZtM0bfvCzv3BqPoqI5wT6JkqJDtw&v=3.exp&libraries=geometry,drawing,places`}
                loadingElement={<div style={{ height: `100%` }} />}
                containerElement={<div style={{ height: `400px` }} />}
                mapElement={<div style={{ height: `100%` }} />}
              />
            )}
          </div>
        </div>
      </div>
      <FooterHome />
    </>
  );
}

export default ContactUs;

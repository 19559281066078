import React, { useEffect, useState } from "react";
import Header from "../layout/header10";
import FooterHome from "../layout/footerHome";
import { getBanners } from "../server";
import bgTree from "../../images/background/futurebg.png";
import FutureDiv from "../../markup/element/BannerSlider/FutureDiv";
import FutureDivReverse from "../element/BannerSlider/FutureDivReverse";

const Future = () => {
  const [banner, setBanner] = useState(null);
  const [viewportWidth, setViewportWidth] = useState(window.innerWidth);

  // Update the viewport width state on window resize
  const updateWindowDimensions = () => {
    setViewportWidth(window.innerWidth);
  };

  useEffect(() => {
    // Add event listener to handle resize
    window.addEventListener("resize", updateWindowDimensions);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", updateWindowDimensions);
    };
  }, []);

  useEffect(() => {
    getBanners({ type: "FUTURE" })
      .then((res) => {
        if (res) {
          setBanner(res?.data[0]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // Define breakpoints
  const isMobile = viewportWidth <= 768;
  const isTablet = viewportWidth > 768 && viewportWidth <= 1024;
  const isDesktop = viewportWidth > 1024;

  return (
    <>
      <Header />
      <div
        style={{
          width: "100%",
          backgroundImage: `url('${bgTree}')`,
          backgroundSize: "cover",
          backgroundPosition: "left center",
          position: "relative",
        }}
      >
        <div
          className="page-content home-slider-10"
          style={{
            marginLeft: isMobile ? 0 : isTablet ? 30 : 60,
            marginRight: isMobile ? 0 : isTablet ? 30 : 60,
          }}
        >
          <div
            className="dlab-bnr-inr overlay-black-middle bg-pt"
            style={{
              // backgroundImage: `url('http://143.198.195.72:8080/abc/${banner?.images[0]?.url}')`,
              backgroundImage: `url('https://rest.cielx.org/abc/${banner?.images[0]?.url}')`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              height: isMobile ? "25vh" : isTablet ? "45vh" : "85vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              className="container"
              style={{
                marginLeft: isMobile ? 20 : isTablet ? 80 : 132, // Adjust margin for tablet and mobile
              }}
            >
              <h1
                className="text-white"
                style={{
                  fontSize: isMobile ? 24 : isTablet ? 40 : 55, // Tablet-specific font size
                  marginTop: isMobile ? 100 : isTablet ? 150 : 250, // Adjust top margin for tablet
                  fontFamily: "Montserrat",
                  fontWeight: 500,
                  lineHeight: "normal",
                  textAlign: "left",
                  color: "#fff",
                  textDecoration: "underline",
                  textDecorationColor: "white",
                  textUnderlineOffset: isMobile ? 7 : isTablet ? 10 : 15, // Tablet-specific underline offset
                }}
              >
                {banner?.title}
              </h1>
            </div>
          </div>
          <div className="content-block" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <div
              className="cielx-new-container"
            // style={{
            //   marginLeft: isMobile ? 40 : isTablet ? 60 : 160, // Adjust margin for tablet
            // }}
            >
              <div className="future-section-wrapper">
                <h3>Building a Better Future With Green Solutions</h3>

                <FutureDiv
                  des1="At Cielx, our unwavering commitment to the well-being of both our planet and its denizens manifests through a
                      meticulously crafted ethos of sustainability and environmental stewardship."
                  des2="We champion the use of recycled materials and responsibly sourced FSC-certified papers, ensuring that each
                      product we deliver not only meets but surpasses rigorous standards of excellence while treading lightly on the
                      Earth."
                  imgPath="tree1.png"
                />

                <FutureDivReverse
                  des1="Our forward-thinking approach integrates sophisticated waste management systems, designed to minimize our
                      ecological footprint and foster a harmonious balance between industry and nature."
                  des2="With an extensive understanding of environmental impacts, we pledge to uphold practices that protect and
                      preserve natural resources for future generations."
                  imgPath="tree2.png"
                />

                <FutureDiv
                  des1="By harmonizing innovation with responsibility, we strive to contribute to a world where environmental harmony
                      and high-quality standards coexist in perfect synergy."
                  des2="Thus, our efforts embody a dedication to nurturing a world where eco-conscious choices are seamlessly
                      intertwined with superior craftsmanship."
                  imgPath="tree3.png"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <FooterHome />
    </>
  );
};

export default Future;

import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import emailjs from 'emailjs-com';
import Footer4 from './footer4';
import { useState } from 'react';
import BlogSlider1 from '../element/blogSlider1';

const Footer = () => {
  const [news, setNews] = useState(null);
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();
    //emailjs.sendForm('YOUR_SERVICE_ID', 'YOUR_TEMPLATE_ID', form.current, 'YOUR_USER_ID')
    emailjs
      .sendForm(
        'gmail',
        'YOUR_TEMPLATE_ID',
        e.target,
        'd9b2e0f5fc72cb94792110e8ff2028f3-us16'
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  };
  return (
    <>
      <footer className="site-footer style1">
        <Footer4 />
      </footer>
    </>
  );
};

export default Footer;

import React, { useState, useEffect } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const CommonBannerSlider = ({ data }) => {
  const [viewportWidth, setViewportWidth] = useState(window.innerWidth);

  // Update the viewport width state on window resize
  const updateWindowDimensions = () => {
    setViewportWidth(window.innerWidth);
  };

  useEffect(() => {
    // Add event listener to handle resize
    window.addEventListener("resize", updateWindowDimensions);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", updateWindowDimensions);
    };
  }, []);
  const isMobile = viewportWidth <= 768;
  const isTablet = viewportWidth > 768 && viewportWidth <= 1024;

  const sliderContent = data;

  return (
    <div
      className="dlab-bnr-inr overlay-black-middle bg-pt"
      style={{
        // backgroundImage: `url('http://143.198.195.72:8080/abc/${sliderContent?.images[0].url}')`,
        backgroundImage: `url('https://rest.cielx.org/abc/${sliderContent?.images[0].url}')`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        height: isMobile ? "25vh" : isTablet ? "45vh" : "85vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        className="container"
        style={{
          marginLeft: isMobile ? 20 : isTablet ? 80 : 200, // Adjust margin for mobile
        }}
      >
        <h1
          className="text-white"
          style={{
            fontSize: isMobile ? 24 : isTablet ? 40 : 55, // Tablet-specific font size
            marginTop: isMobile ? 100 : isTablet ? 150 : 250, // Adjust top margin for tablet
            fontFamily: "Montserrat",
            fontWeight: 500,
            lineHeight: "160%",
            textAlign: "left",
            color: "#fff",
            textDecoration: "underline",
            textDecorationColor: "white",
            textUnderlineOffset: isMobile ? 7 : isTablet ? 10 : 15,
          }}
        >
          {sliderContent?.title}
        </h1>
      </div>
    </div>
  );
};

export default CommonBannerSlider;

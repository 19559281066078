import React, { Component, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Header from '../../layout/header10'
import Footer from '../../layout/footer'
import Testimonial1 from '../../element/testimonial1'
import HomeSlider from '../../element/homeSlider2'
import {
    aboutSectionContentFirst,
    aboutUsPageAboutSection,
    aboutUsPageAboutSection2,
    homeSliderContent8
} from '../../element/SliderContent'
import AboutSection from '../../element/aboutSection'
import AboutSectionReverse from '../../element/aboutSectionReverse'
import FooterHome from '../../layout/footerHome'
import { getBanners, getSectionContent, IMAGE_PREFIX } from '../../server'
import CommonBannerSlider from '../../element/CommonBannerSlider'
import { NatureSection } from '../../element/NatureSection'

const GrowthTowardsNature = () => {
    const [banner, setBanner] = useState(null)
    const [greenData, setGreenData] = useState(null)
    const [natureData, setNatureData] = useState(null)
    const [greenPath1Data, setGreenPath1Data] = useState(null)
    const [greenPath2Data, setGreenPath2Data] = useState(null)

    const fetchGreenData = () => {
        getSectionContent({ type: "GREEN_EVOLUTION" })
            .then((res) => {
                console.log("section :", res);
                if (res) {
                    setGreenData(res?.data);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }
    const fetchNatureData = () => {
        getSectionContent({ type: "NATURE_AND_SUSTAINABILITY" })
            .then((res) => {
                console.log("section :", res);
                if (res) {
                    setNatureData(res?.data);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }
    const fetchGreenPath1Data = () => {
        getSectionContent({ type: "COMMITTED_TO_A_GREENER_PATH_1" })
            .then((res) => {
                console.log("section :", res);
                if (res) {
                    setGreenPath1Data(res?.data);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }
    const fetchGreenPath2Data = () => {
        getSectionContent({ type: "COMMITTED_TO_A_GREENER_PATH_2" })
            .then((res) => {
                console.log("section :", res);
                if (res) {
                    setGreenPath2Data(res?.data);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    useEffect(() => {
        getBanners({ type: 'GROWTH_TOWARDS_NATURE' })
            .then(res => {
                console.log('resAboutUs', res)
                if (res) {
                    setBanner(res?.data[0])
                }
            })
            .catch(err => {
                console.log(err)
            })
        fetchGreenData()
        fetchGreenPath1Data()
        fetchGreenPath2Data()
        fetchNatureData()
    }, [])
    return (
        <>
            <Header />

            <div
                className='page-content home-slider-10'
                style={{ backgroundColor: '#E1E1E1' }}
            >
                <CommonBannerSlider
                    data={banner}
                    contentWrapperCls='sliderStyle10'
                    subTitleCls='text-primary'
                    btnCls='text-primary'
                    btnText='Continue The Journey'
                />
                <div className='content-block'>
                    <div className={`about-section-wrapper about-section-solid`}>
                        <div className='cielx-new-container'>
                            <div className='growth-div-one'>
                                <img
                                    className='future-traced'
                                    alt='Future traced'

                                    src={`${IMAGE_PREFIX}/${greenData?.backgroundImage?.url}`}
                                />
                                <div className='div'>
                                    <div className='text-wrapper'>{greenData?.mainTitle}</div>
                                    <p className='cielx-stands-as-a'>
                                        {greenData?.description1}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div
                        className={`about-section-wrapper about-section-solid`}
                        style={{ backgroundColor: 'rgba(205 ,215, 219 , 0.84)' }}
                    >
                        <div className='cielx-new-container'>
                            <NatureSection sectionData={natureData} />
                        </div>
                    </div>

                    <div className={`about-section-wrapper about-section-solid`}>
                        <div className='cielx-new-container'>
                            <div className='growth-div-two'>
                                <div className='div'>
                                    <p className='text-wrapper'>{greenPath1Data?.mainTitle}</p>
                                    <p className='at-cielx-our'>
                                        {greenPath1Data?.description1}
                                        <br />
                                        <br />
                                        {greenPath1Data?.description2}
                                        <br />
                                        <br />
                                        <img
                                            className='image'
                                            alt='Image'
                                            src={`${IMAGE_PREFIX}/${greenPath1Data?.backgroundImage?.url}`}
                                        />
                                        <br />
                                        <br />
                                        {greenPath2Data?.description1}
                                        <br />
                                        <br />
                                        {greenPath2Data?.description2}
                                        <br />
                                        <br />
                                        {greenPath2Data?.description3}
                                    </p>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <FooterHome />
        </>
    )
}

export default GrowthTowardsNature
